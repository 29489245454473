import { useState, useEffect } from "react";
import { Stack, Typography } from "@mui/joy";
import { FaLongArrowAltRight } from "react-icons/fa";

export default function RastreamentosDiligenciamento(props) {

    const [arrayRastreamentos, setArrayRastreamentos] = useState([]);

    useEffect(() => {
        //Obtendo o indice da baixa no array
        const indiceBaixa = props.rastreamentos.data.findIndex(rastreamento => (rastreamento.ULTIMO_MOVIMENTO.split(" - ")[0] == "1.1.03") || (rastreamento.ULTIMO_MOVIMENTO.split(" - ")[0] == "1.1.21") || (rastreamento.ULTIMO_MOVIMENTO.split(" - ")[0] == "1.1.02"));
        const indiceCompra = props.rastreamentos.data.findIndex(rastreamento => (rastreamento.ULTIMO_MOVIMENTO.split(" - ")[0] != "1.1.03") && (rastreamento.ULTIMO_MOVIMENTO.split(" - ")[0] != "1.1.21"));
        if (props.etapaAtual == "Baixas") {
            console.log("props etapa igual a baixas");
            console.log(props.rastreamentos);
            console.log(props.rastreamentos.data[indiceBaixa]);
            prepararArrayRastreamentos(props.rastreamentos.data[indiceBaixa]);
        }
        else {
            console.log("props etapa diferente de baixas");
            console.log(props.rastreamentos);
            console.log(props.rastreamentos.data[indiceCompra]);
            prepararArrayRastreamentos(props.rastreamentos.data[indiceCompra]);
        }
    }, []);

    const prepararArrayRastreamentos = (dadosRastreamentos) => {
        console.log("Redemption prepararArrayRastreamentos");
        console.log(dadosRastreamentos);
        //Preenchendo rastreamentos
        let rastreamentos = [];
        if (dadosRastreamentos?.PRIMOV != undefined) {
            rastreamentos.push(
                {
                    titulo: dadosRastreamentos.PRIMOV,
                    numero: dadosRastreamentos.PRINUMEROMOV,
                    dataEmissao: dadosRastreamentos.DATAEMISSAO,
                    responsavel: dadosRastreamentos.RESPONSAVEL_PRI,
                    status: dadosRastreamentos.STATUS,
                }
            );
        }

        if (dadosRastreamentos?.SEGMOV != undefined) {
            rastreamentos.push(
                {
                    titulo: dadosRastreamentos.SEGMOV,
                    numero: dadosRastreamentos.SEGNUMEROMOV,
                    dataEmissao: dadosRastreamentos.SEGDATAEMISSAO,
                    tempoEmDias: dadosRastreamentos.DIFFDIASEGPRI,
                    responsavel: dadosRastreamentos.RESPONSAVEL_SEG,
                    status: dadosRastreamentos.STATUS1,
                }
            );
        }

        if (dadosRastreamentos?.TERCMOV != undefined) {
            rastreamentos.push(
                {
                    titulo: dadosRastreamentos.TERCMOV,
                    numero: dadosRastreamentos.TERCNUMEROMOV,
                    dataEmissao: dadosRastreamentos.TERCDATAEMISSAO,
                    tempoEmDias: dadosRastreamentos.DIFFDIATERCSEG,
                    responsavel: dadosRastreamentos.RESPONSAVEL_TERC,
                    status: dadosRastreamentos.STATUS2,
                }
            );
        }

        if (dadosRastreamentos?.CODCOTACAO != undefined) {
            rastreamentos.push(
                {
                    titulo: "Cotação",
                    numero: dadosRastreamentos.CODCOTACAO,
                    dataEmissao: dadosRastreamentos.DATACOTACAO,
                    status: dadosRastreamentos.STATUS_COTACAO,
                    responsavel: dadosRastreamentos.RESPONSAVEL_COTACAO != undefined ? dadosRastreamentos.RESPONSAVEL_COTACAO.toLowerCase() : ""
                }

            );
        }

        if (dadosRastreamentos?.QUARTMOV != undefined) {
            rastreamentos.push(
                {
                    titulo: dadosRastreamentos.QUARTMOV,
                    numero: dadosRastreamentos.QUARTNUMEROMOV,
                    dataEmissao: dadosRastreamentos.QUARTDATAEMISSAO,
                    tempoEmDias: dadosRastreamentos.DIFFDIAQUATERC,
                    responsavel: dadosRastreamentos.COMPRADOR,
                    status: dadosRastreamentos.STATUS3,
                }
            );
        }

        if (dadosRastreamentos?.QUIMOV != undefined) {
            rastreamentos.push(
                {
                    titulo: dadosRastreamentos.QUIMOV,
                    numero: dadosRastreamentos.QUINUMEROMOV,
                    dataEmissao: dadosRastreamentos.QUIDATAEMISSAO,
                    tempoEmDias: dadosRastreamentos.DIFFDIAQUIQUA,
                    responsavel: dadosRastreamentos.RESPONSAVEL_QUI,
                    status: dadosRastreamentos.STATUS4,
                }
            );
        }

        if (dadosRastreamentos?.SEXMOV != undefined) {
            rastreamentos.push(
                {
                    titulo: dadosRastreamentos.SEXMOV,
                    numero: dadosRastreamentos.SEXNUMEROMOV,
                    dataEmissao: dadosRastreamentos.SEXDATAEMISSAO,
                    tempoEmDias: dadosRastreamentos.DIFFDIASEXQUI,
                    responsavel: dadosRastreamentos.RESPONSAVEL_SEX,
                    status: dadosRastreamentos.STATUS5,
                }
            );
        }

        // console.log(rastreamentos);
        setArrayRastreamentos(rastreamentos);
    }

    return (
        <Stack flexDirection={"row"} alignItems="center" flexWrap={"wrap"}>
            {arrayRastreamentos.map((rastreamento, indice) => {
                return (
                    <Stack key={indice} flexDirection="row" alignItems="center">
                        <Stack backgroundColor="#fedc7a" p="20px" borderRadius={"10px"} boxShadow="md" width="250px" mr="20px" mb="20px">
                            <Typography level="title-lg" sx={{ marginBottom: "10px", fontSize: 16 }}>{rastreamento.titulo}</Typography>
                            <Typography>Número: {rastreamento.numero}</Typography>
                            <Typography>Data Emissão: {rastreamento.dataEmissao}</Typography>
                            <Typography>Tempo Em Dias: {rastreamento.tempoEmDias}</Typography>
                            <Typography>Responsável: {rastreamento.responsavel}</Typography>
                        </Stack>
                        {arrayRastreamentos.length != (indice + 1) ?
                            <Stack mr="20px">
                                <FaLongArrowAltRight size={30} color="#444" />
                            </Stack>
                            : null
                        }
                    </Stack>
                );
            })}
        </Stack>
    )
}