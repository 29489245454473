import axios from "axios";
import { useState, useEffect } from "react";

//Componentes
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";
import InformacoesItem from "../../../../components/InformacoesItem";
import Anexos from "../../../../components/Anexos";
import SelecaoItem from "../../../../components/SelecaoItem";
import ConfirmarExclusao from "../../../../components/ConfirmarExclusao";
import DadosCotacao from "../../../../components/DadosCotacao";
//Utils
import { obterProximaEtapa } from "../../../../utils/etapasDoProcesso";
import { dataAtual, formatarDataHoraBrasil } from "../../../../utils/datas";
import { formatarMoeda } from "../../../../utils/moedas";
import { definirProximaEtapa, obterEtapasAnteriores, obterWorkflowEtapaAtual } from "../../../../utils/etapasDoProcesso";

import {
  Stack,
  Typography,
  Button,
  Select,
  Option,
  Input,
  FormLabel,
  Table,
  ToggleButtonGroup,
  Link,
  Checkbox,
  Textarea
} from "@mui/joy";
import Tooltip from '@mui/joy/Tooltip';
import { FaCircleInfo } from "react-icons/fa6";

import { toast } from 'react-toastify';

//icones react
import { FaRegEdit, FaRegTrashAlt, FaDownload, FaFolderOpen } from "react-icons/fa";
import { GoLaw } from "react-icons/go";

//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';

//Apis do RM
import { RM, api } from '../../../../services/api';

import { useNavigate, useLocation, useParams } from "react-router-dom";

import Autocomplete from '@mui/joy/Autocomplete';
import { verificaNecessidadeJuridico } from "../../../../utils/processarDados";
import realizaConsulta from "../../../../services/rm";

import { Modal, ModalDialog, ModalClose } from "@mui/joy";

let token = "";
let usuarioID = 0;
let usuarioLogin = "";

export default function AprovacaoCompras(props) {
  /*
      TODO: Aprovar por cotação. Exibir anexos da solicitação:
  */
  document.addEventListener("keydown", function (event) {
    // Verifique se a tecla pressionada é Enter (código 13)
    if (event.keyCode === 13) {
      // Chame a sua função desejada aqui
      event.preventDefault();
    }
  });

  const navigate = useNavigate(); //navegacao entre components
  const { id_param } = useParams(); // id da cotação passada por url
  const location = useLocation(); //navegacao entre components
  const mobile = useMediaQuery('(max-width:1200px)'); //Telas menores que 1200px de largura serão considerados mobile

  const [valueTogglePagina, setValueTogglePagina] = useState("formulario"); //toggle que muda para aba formulario/anexo
  const [openModal, setOpenModal] = useState(false); //Modal de informar qtd a solicitar do item pedido
  const [openModalListaItem, setOpenModalListaItem] = useState(false); //Modal para exibir lista de itens quando pesquisar por descrição
  const [numeroProcesso, setNumeroProcesso] = useState("");
  const [anexos, setAnexos] = useState([])
  const [uploadAnexos, setUploadAnexos] = useState(new FormData());
  const [anexosSelecionados, setAnexosSelecionados] = useState(null); // guarda arquivos selecionados da aba anexos, para usuario nao perder os pré-selecinados antes de enviar
  const [decisaoAprovacao, setDecisaoAprovacao] = useState("");
  const [numeroOS, setNumeroOS] = useState("");
  const [osVinculada, setOsVinculada] = useState(false);
  const [indiceItemConsultado, setIndiceItemConsultado] = useState(0);
  const [nomeCodigoNovoItem, setNomeCodigoNovoItem] = useState("");
  const [itensEncontradosBusca, setItensEncontradosBusca] = useState([]);
  const [itemTemporario, setItemTemporario] = useState({});
  const [dialogExclusao, setDialogExclusao] = useState(false);
  const [atualizarEstados, setAtualizarEstados] = useState(false);
  const [etapaAtual, setEtapaAtual] = useState("");
  const [numeroPms, setNumeroPms] = useState("");
  const [permissaoAprovador, setPermissaoAprovador] = useState(false);
  const [informacoesGerais, setInformacoesGerais] = useState({ coligadas: [], filiais: [], locaisDeEstoque: [], centrosDeCusto: [] })
  const [etapasAnteriores, setEtapasAnteriores] = useState([]);
  const [etapaARetornar, setEtapaARetornar] = useState("");
  const [passarJuridico, setPassarJuridico] = useState(false);
  const [possuiAdiantamento, setPossuiAdiantamento] = useState(false);

  const [itensParaFornecedor, setItensParaFornecedor] = useState([]);

  //Campos cotação:
  const [numeroCotacao, setNumeroCotacao] = useState();
  const [cotacao, setCotacao] = useState(
    {
      solicitacao_id: "",
      dataEntrega: "",
      codCotacao: "",
      comprador: "",
      fornecedor: "",
      condPagamento: "",
      tipoFrete: "",
      created_at: "",
      updated_at: "",
      numeroMov6: "",
      numeroMov8: "",
      numeroMov22: "",
      numeroMov23: "",
      status: "",
      etapaAtual: "",
      decisao: "",
      serie: "",
      totalCotacao: "",
      itens: [],
      pedidoContrato: "",
      prazo_entrega: "",
      solicitacao: undefined,
      data_efetiva_entrega: ""
    }
  );
  const [infoQtdDiasEntrega, setInfoQtdDiasEntrega] = useState("");
  const [motivoReprovacao, setMotivoReprovacao] = useState("");
  const [motivoCancelamento, setMotivoCancelamento] = useState("");
  const [informacoesAd, setInformacoesAd] = useState("");

  //este form terá os dados da alçada de aprovacao que será utilizada (criterio urgente e tipoPedido manutenção serão priorizados se existirem)
  const [formulario, setFormulario] = useState(
    {
      id: "",
      criterio: "NO",
      tipoPedido: "001",
      numeroMov1: "",
      localEstoque: "",
      filial: "",
      coligada: "",
      valorDeLimiteCadastrado: "",
      limiteMensalAtingido: false,
      valorSomadoObraMes: ""
    }
  );
  const [solicitacoesOrigem, setSolicitacoesOrigem] = useState([]); // lista de ids com todas as solicitacoes de origem da cotação atual:
  const [exibeMapaCotacao, setExibeMapaCotacao] = useState(false);
  const [usuarioPertenceObraCotacao, setUsuarioPertenceObraCotacao] = useState(false);
  const [openModalLimite, setOpenModalLimite] = useState(false);

  useEffect(() => {

    //Obtendo o token
    if (JSON.parse(localStorage.getItem("usuario")) != null) {
      token = JSON.parse(localStorage.getItem('usuario')).token;
      usuarioID = JSON.parse(localStorage.getItem('usuario')).user.id;
      usuarioLogin = JSON.parse(localStorage.getItem('usuario')).user.login;
    }
    //Parâmetro cotação:
    if (id_param) {
      checaLimiteValorAgregado();
      //Buscar cotação passada por parâmetro:
      api.get(`/cotacoes/${id_param}`,
        {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      ).then(function ({ data }) {
        if (data.data.totalCotacao >= 500000) {
          console.log("É maior que 500000");
          setPassarJuridico(true);
        }
        console.log(data.data);
        setCotacao(data.data);
        setInfoQtdDiasEntrega("Quantidade de dias para a Entrega do Material Após Emissão de OC: "+data.data?.prazo_entrega);

        //Pegar criterio e tipoPedido (tipo manutencao e criterio emergencial são P.0, se eles aparecerem, a aprovacao seguirá o caminho deles)
        let idsSolicitacaoItens = [];
        let adiantamento = false;

        data.data.itens.forEach((item, index) => {
          if (item.descricao.toLowerCase().includes("adiantamento")) {
            console.log(item);
            adiantamento = true;
          }
          if (["005", "006", "007", "011", "012", "013", "014"].includes(item.solicitacao.tipoPedido)) {
            formulario.tipoPedido = item.solicitacao.tipoPedido;
          }
          if ((item.solicitacao.criterio == "MP") || (item.solicitacao.criterio == "UR")) {
            formulario.criterio = item.solicitacao.criterio;
          }
          //pegar do primeiro item da cotacao as informacaoes padrão da solicitacao para usa-las na hora de gerar o 1108:
          if (index == 0) {
            formulario.id = item.solicitacao.id;
            formulario.numeroMov1 = item.solicitacao.numeroMov1;
            formulario.localEstoque = item.solicitacao.localEstoque;
            formulario.filial = item.solicitacao.filial;
            formulario.coligada = item.solicitacao.coligada;
            formulario.limiteMensalAtingido = item.limiteMensalAtingido;
            formulario.valorSomadoObraMes = item.valorSomadoObraMes;
            formulario.valorDeLimiteCadastrado = item.valorDeLimiteCadastrado;
          }
          //guardar todos os ids de solicitacoes dos itens da cotação:
          if (idsSolicitacaoItens.includes(item.solicitacao_id) == false) {
            idsSolicitacaoItens.push(item.solicitacao_id);
          }
        });
        setSolicitacoesOrigem(idsSolicitacaoItens);
        setPossuiAdiantamento(adiantamento);
        if (adiantamento) {
          setPassarJuridico(true);
        }

        setEtapaAtual(data.data.etapaAtual); //Etapa atual da cotação

        //TODO: pegar etapas anteriores tabela L.F:
        //workflow da etapa atual possui as etapas que o aprovador atual pode retornar caso reprove:
        obterWorkflowEtapaAtual(data.data.etapaAtual).then((result) => {
          let _etapaAtual = result;
          let _retornos = JSON.parse(_etapaAtual.retornos);
          let indiceAprovador = -1;
          // identifica posicao da etapa atual, no campo retornos do workflow:
          for (let i = 0; i < _retornos.length; i++) {
            if (_retornos[i].etapa === data.data.etapaAtual) {
              indiceAprovador = i;
              break;
            }
          }
          //sinaliza que encontrou etapa atual:
          if (indiceAprovador !== -1) {
            //pegar as etapas anteriores à etapa atual que estao no array retornos:
            let _retornosAnteriores = _retornos.slice(0, indiceAprovador);
            // mantem apenas as etapas com campo 1.1.04 = true;
            let etapasAnteriores1104 = _retornosAnteriores.filter(item => item["1.1.04"] === true);
            // monta lista de strings para o autocomplete de etapas retornar
            etapasAnteriores1104 = etapasAnteriores1104.map(item => item.etapa);

            if (formulario.tipoPedido) {
              //se tipo pedido origem for de materiais e servicos, eliminar etapas que nao correspondem ao tipo dele
              if (["005", "006", "007", "011", "012", "013", "014"].includes(formulario.tipoPedido) == false) {
                etapasAnteriores1104 = etapasAnteriores1104.filter(item => item !== "Aprov. diretoria equipamentos" && item !== "Aprov. gestor corporativo equipamentos");
              } else {
                //se tipo pedido for manutencao equipamentos, eliminar etapas desnecessarias
                etapasAnteriores1104 = etapasAnteriores1104.filter(item => item !== "Aprov. diretoria operações");
              }
            }  
            
            //Lista de etapas anteriores: só poderá voltar para cotação se for o aprov. mcp1, (update: mcp2 e mcpf), caso contrário, não tem essa opção:
            // if(etapaAtual == "Aprov. mcp1" || etapaAtual == "Aprov. mcp2" || etapaAtual == "Aprov. mcpf"){
            //   setEtapasAnteriores([...etapasAnteriores1104, "Comprador"]); //cotação R.M é uma etapa exclusiva da tela de compras.
            // }
            // else {
            //   setEtapasAnteriores([...etapasAnteriores1104]);
            // }
            setEtapasAnteriores([...etapasAnteriores1104]);
            

          }
        }).catch((error) => {
          console.log('Erro ao obter etapas workflow a retornar:', error);
          toast.error(JSON.stringify(error.response));
        });

        setNumeroCotacao(data.data.id);
        setNumeroProcesso(data.data.solicitacao_id);

        //Definindo a permissão de aprovação
        const grupos = JSON.parse(localStorage.getItem("usuario")).grupo;
        var obra = "";//JSON.parse(localStorage.getItem("usuario")).obra; //??
        const idDoUsuario = JSON.parse(localStorage.getItem("usuario")).user.id;

        //Varrer variável grupos do usuário e identificar se a atividade atual pertence a ele:
        grupos.forEach((grupo) => {
          //se perfil do grupo do usuário corresponde a etapa atual da cotação,
          // e a cotacao tenha mesmo localEstoque que o grupo do usuário:
          if (grupo.obra == data.data.codloc.split(" - ")[0]) {
            setUsuarioPertenceObraCotacao(true);
          }
          if ((grupo.perfil == data.data.etapaAtual) && (grupo.obra == data.data.codloc.split(" - ")[0]) && (data.data.etapaAtual != "Comprador")
            && (grupo.tipoPedido.includes(data.data.tipoPedidoId))) {
            obra = grupo.obra;
            setPermissaoAprovador(true);
          }
        });
      }).catch(function (err) {
        console.error(err);
        toast.error(JSON.stringify(err.response.data));
        toast.error("Ocorreu um erro ao carregar a cotação. Por favor, atualize a página.");
      })
    }
    //Carregar locais de estoque e centros de custo do solicitante
    const usuario = JSON.parse(localStorage.getItem('usuario')).user.login;
    // let id_toast = toast.loading("Obtendo informações do RM...", { toastId: "toast" });
    //Aqui um trecho de cleanup, que serve para limpar variaveis de sessão e recursos assim que este componente for desmontado:
    return () => {
      localStorage.removeItem('qtdArquivosSelecionados');
      localStorage.removeItem('nomesArquivosSelecionados');
    };
  }, []);


  //Atualizar variável de estado que armazena os dados do formulário
  const atualizarFormulario = (chave, valor, item = false, indiceItem) => {
    let objFormulario = formulario;
    if (item) {
      osVinculada ?
        objFormulario.itens[indiceItem][chave] = valor :
        objFormulario.itens[indiceItemConsultado][chave] = valor;
    }
    else {
      objFormulario[chave] = valor;
    }
    setFormulario(objFormulario);
    setAtualizarEstados(!atualizarEstados);
  }

  // const buscarItem = () => {
  //   if (nomeCodigoNovoItem) {
  //     const id = toast.loading("Buscando o item informado");
  //     const isNumber = (!isNaN(parseInt(nomeCodigoNovoItem.replace("/^\d+$/.test(str)"))));
  //     const codigo = isNumber ? nomeCodigoNovoItem.padStart(5, "0") : null;
  //     const descricao = isNumber ? null : nomeCodigoNovoItem;

  //     realizaConsulta('01.001.011', {
  //       params: {
  //         CODIGOPRD_S: codigo,
  //         NOME_PRODUTO: descricao,
  //         CODLOC: formulario.localEstoque,
  //         CODFILIAL: formulario.filial
  //       }
  //     }).then(function ({ data }) {
  //         if (data.length > 1) {
  //           toast.update(id, { render: "Item(s) encontrado(s)", type: "success", isLoading: false, autoClose: 1000 });
  //           setItensEncontradosBusca(data);
  //           setOpenModalListaItem(true);
  //           setNomeCodigoNovoItem("");
  //         } else if (data.length == 1) {
  //           toast.update(id, { render: "Item encontrado", type: "success", isLoading: false, autoClose: 1000 });
  //           novoItem(data[0]);
  //           setNomeCodigoNovoItem("");
  //         } else {
  //           if (!formulario.localEstoque) {
  //             toast.update(id, { render: "Escolha um local de estoque para adicionar um item", type: "info", isLoading: false, autoClose: 2500 });
  //           }
  //           else {
  //             toast.update(id, { render: "Item  não encontrado neste local de estoque", type: "warning", isLoading: false, autoClose: 2500 });
  //           }
  //         }
  //       }).catch(function (err) {
  //         toast.update(id, { render: "Erro ao adicionar novo item", type: "error", isLoading: false, autoClose: 1000 });
  //       });
  //   }
  // }

  //Adicionar novo item manualmente no formulário
  const novoItem = (dados) => {
    if ((formulario.itens.length > 0) &&
      (formulario.itens.find(item => item.codProduto == dados.CODIGOPRD))) {
      toast.warning("Item ja existe na tabela");
    }
    else {
      const dadosFormatados = {
        codProduto: dados.CODIGOPRD,
        idPrd: dados.IDPRD,
        descricao: dados.NOMEFANTASIA,
        quantidadeProduto: dados.QUANTIDADEARECEBER,
        QUANTIDADEARECEBER: "",
        CODTMV: "",
        estoque: dados.SALDOFISICO2,
        saldoMin: dados.SALDO_MIN,
        saldoMax: dados["SALDO MAX"],
        CODUND: dados.UNIDADE,
        saldoContrato: dados.SALDODISPCONT,
        pedidoAFornecedor: dados.PEDIDOF,
        precoUntAlvo: "",
        idObjetoOficina: "",
        observacoesItem: "",
        naoUsarEstoque: false,
        naoUsarContrato: false,
      }
      setItemTemporario(dadosFormatados);
      setOpenModal(true);
    }
  }

  const acrescentarItem = () => {
    let itens = formulario.itens;
    if (itens.length > 0) {
      let itemEncontrado = false;
      itens.forEach((item, indice) => {
        if (item.codProduto == itemTemporario.codProduto) {
          itens[indice] = itemTemporario;
          itemEncontrado = true;
        }
      });
      if (!itemEncontrado) {
        itens.push(itemTemporario);
      }
    }
    else {
      itens.push(itemTemporario);
    }
    formulario.itens = itens;
    setOpenModal(false);
    setItemTemporario({});
  }

  const excluirItem = (indice) => {
    setDialogExclusao(false)
    setIndiceItemConsultado(0);
    formulario.itens.splice(indice, 1);
  }

  const rolarParaOCampo = (decisao) => {
    if (decisao != "Aprovado") {
      //Precisa haver um delay na chamada da função de rolagem porque a renderização do componente é assíncrona
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 50);
    }
  }

  const movimentarProcesso = async (decisao = null) => {
    let decisaoFinal = "";
    if ((decisaoAprovacao == "Reprovado") && (motivoReprovacao == "")) {
      toast.warning("O campo motivo da reprovação é obrigatório");
      return;
    }
    if ((decisaoAprovacao == "Reprovado") && (etapaARetornar == "")) {
      toast.warning("Para reprovar, é necessário informar o campo 'Retornar para Atividade'.");
      return;
    }
    if ((decisaoAprovacao == "Cancelado") && (motivoCancelamento == "")) {
      toast.warning("O campo motivo do cancelamento é obrigatório");
      return;
    }

    if (decisao != null) {
      decisaoFinal = decisao;
    }
    else {
      decisaoFinal = decisaoAprovacao;
    }

    // console.log("Achou decisaoFinal?");
    // console.log(decisaoFinal);

    console.log("Checar formulario antes de obter proxima etapa: ", formulario);
    //TODO: Definir critério baseado nas solicitações de origem, (prioridade é seguir o fluxo emergencial e de manutenção equipamentos)
    var proximaEtapa = await obterProximaEtapa(formulario, etapaAtual, decisaoFinal, etapaARetornar, cotacao.totalCotacao);

    // console.log("Achou proxEtapa?");
    // console.log(proximaEtapa);

    cotacao.motivoCancelamento = motivoCancelamento;
    cotacao.motivoReprovacao = motivoReprovacao;
    cotacao.informacoesAd = informacoesAd;
    cotacao.usuario_id = JSON.parse(localStorage.getItem("usuario")).user.id;
    cotacao.cotacao_id = cotacao.id;
    cotacao.decisao = decisaoFinal;
    cotacao.etapaAtual = proximaEtapa;
    cotacao.etapaAnterior = etapaAtual;

    //Excecao Gestor aprovou mas extrapolou o limite-mês-obra: subir o nivel de alçada aprovacao, para validar sua decisao pela diretoria.
    if( decisaoAprovacao == "Aprovado" &&  etapaAtual == "Aprov. gestor contrato/equipamentos" && proximaEtapa == "diligenciamento" && cotacao.limiteMensalAtingido) {
      const confirmar_aprovacao = window.confirm("Seu limite definido de gasto mensal/obra foi atingido. Ao aprovar, seu pedido seguirá para aprovação da diretoria.");
      if (confirmar_aprovacao) {
        //se tipo pedido for manutencao equipamentos, envia pro diretor certo>
        if(["005", "006", "007", "011", "012", "013", "014"].includes(cotacao.solicitacao?.tipoPedido)){
          cotacao.etapaAtual = "Aprov. diretoria equipamentos";
          proximaEtapa = "Aprov. diretoria equipamentos";
        }else {
          cotacao.etapaAtual = "Aprov. diretoria operações";
          proximaEtapa = "Aprov. diretoria operações";
        }
      }else {
        return;
      }
    }

    //Excecao de proxima - caso precise de etapa do juridico (apenas quando for  aprov. mcpf) alterar proxima etapa enviando pro juridico
    if (etapaAtual == "Aprov. mcpf") {

      let temJuridico = verificaNecessidadeJuridico(cotacao, limiteValorAgregado);

      if (temJuridico || passarJuridico) {
        cotacao.etapaAtual = "Jurídico";
      }
    }

    if (decisaoFinal == "Cancelado") {
      cotacao.status = 2; //se cancelou marcar cancelado
    }
    else if (proximaEtapa == "diligenciamento") {
      console.log("Cade o mov finalmente?");
      console.log(cotacao.pedidoContrato);

      let movimentoFinal = "1.1.08";
      if (cotacao.pedidoContrato == 1 || possuiAdiantamento || cotacao.totalCotacao > 500000) {
        movimentoFinal = "1.1.23";
      }
      await montaEvolucaoPedidoFinal(proximaEtapa, formulario, cotacao, movimentoFinal);
    }
    else if (proximaEtapa == "Comprador") {
      //TODO: chamar metodo de cancelar o movimento 1106.
      await cancelaMovimento(formulario, cotacao);
      cotacao.status = 1;
    }
    else {
      cotacao.status = 1; //senao está em andamento
    }

    // console.log("Achou objeto cotacao?");
    // console.log({ cotacao });

    api.put(`/cotacoes/` + cotacao.id,
      cotacao,
      {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
    )
      .then(function ({ data }) {
        toast.success("Movimentação realizada com sucesso");

        api.post(`/anexos?solicitacao_id=${cotacao.solicitacao_id}&responsavel=${usuarioLogin}`,
          uploadAnexos,
          {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(function (response) {
          // if (proximaEtapa != "diligenciamento") {


          if (etapaAtual == "Jurídico") {
            // console.log({ itensParaFornecedor });
            // console.log("Entrou na condição de etapa do jurídico");
            api.post(`/anexosContratos`,
              itensParaFornecedor,
              {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
              }
            ).then(function (response) {
              // console.log("Enviou os nomes dos arquivos para notificar o fornecedor");
              // console.log(response);
              navigate("/compras");
            }).catch((err => {
              console.error(err);
              toast.error(JSON.stringify(err.response.data));
            }));
          }
          else {
            navigate("/compras");
          }
        }).catch(function (err) {
          console.error(err);
          toast.error(JSON.stringify(err.response.data));
        })
      })
      .catch(function (err) {
        console.error(err);
        toast.error(JSON.stringify(err.response.data));
        toast.error("Ocorreu um erro ao salvar o movimento, consulte o log");
      });
  }

  const definirStatus = (decisao) => {
    //se aprovado ou reprovado, continua em andamento:
    if ((decisao == "Aprovado") || (decisao == "Reprovado")) {
      formulario.status = 1;
      return;
    }
    if (decisao == "Cancelado") {
      formulario.status = 2;
      return;
    }
    if (decisao == "Concluído") {
      formulario.status = 3;
      return;
    }
  }

  const montaEvolucaoPedidoFinal = async (proximaEtapa, formulario, cotacao, movimentoFinal) => {
    // caso a próxima etapa seja 'diligenciamento', a etapa atual fará evolução do movimento:
    let pedidoEvolucaoFinal = {};
    let itensEvolucao = [];

    if (proximaEtapa == "diligenciamento") {

      //Header do pedido 1102 (1102 nao precisa enviar itens):
      pedidoEvolucaoFinal = {
        CODCOLIGADA: formulario.coligada,
        CODMOVIMENTODESTINO: movimentoFinal,
        CODMOVIMENTOORIGEM: "1.1.06",
        IDMOV: cotacao.numeroMov6,
        ID01: formulario.numeroMov1,
        CODUSUARIO: "fluig", //fluig sempre gera o movimento final do processo.
        ITENS: [{ QUANTIDADE: 1, SEQUENCIA: 1 }],
        SERIE: "OCA",
        PROCESSO_ID: formulario.id,
        CODLOC: formulario.localEstoque,
        CODFILIAL: formulario.filial,
      }

      try {
        let id_toast = toast.loading("Salvando movimento ..");

        //Requisicao para gerar movimento 1102
        await axios.post(`https://almox.pelicano.eng.br/php/movimento/faturamento/Faturamento.php`,
          pedidoEvolucaoFinal, {
          timeout: 100000
        }
        ).then(async function (response) {

          let retorno = response.data;
          let retornoPrefixo = null;
          if (movimentoFinal == "1.1.08") {
            retornoPrefixo = retorno.substring(0, 2);
          } else if (movimentoFinal == "1.1.23") {
            retornoPrefixo = retorno.substring(0, 2);
          }

          let sucessoAoGerarMovFinal = false;
          if ((retornoPrefixo == "1;") && (movimentoFinal == "1.1.08")) { //caso seja destino 1.1.08
            //Guardar número do movimento 1108 Gerado:
            cotacao.numeroMov8 = retorno.split(";")[1];
            cotacao.status = 1; //diligenciamento continua com status em andamento, até o RM gerar nota, aí fica concluido
            toast.update(id_toast, { render: "O movimento " + movimentoFinal + " foi Salvo com sucesso!", type: "success", isLoading: false, autoClose: 2000 });
            sucessoAoGerarMovFinal = true;
          }
          else if ((retornoPrefixo == "1;") && (movimentoFinal == "1.1.23")) { //caso seja destino 1.1.23
            cotacao.numeroMov23 = retorno.split(";")[1];
            cotacao.status = 1; //diligenciamento continua com status em andamento, até o RM gerar nota, aí fica concluido
            toast.update(id_toast, { render: "O movimento " + movimentoFinal + " foi Salvo com sucesso!", type: "success", isLoading: false, autoClose: 2000 });
            sucessoAoGerarMovFinal = true;
          }
          else {
            const mensagemErro = "Aviso do RM ao gerar movimento " + movimentoFinal + ": " + response.data.split("=======================================")[0];
            toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
          }
        }).catch(function (err) {
          console.log(err);
          toast.error(JSON.stringify(err.response.data));
          const mensagemErro = "Aviso do RM ao gerar movimento " + movimentoFinal + ": " + err.data.split("=======================================")[0];
          toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
        })
      } catch (error) {
        console.error("Erro nas requisições 1108/1.1.23");
      }
    }//if diligenciamento
  }

  const cancelaMovimento = async (formulario, cotacao) => {

    let jsonCancelaMovimento = {
      coligada: 1,
      idmov: cotacao.numeroMov6
      // CODCOLIGADA: formulario.coligada,
      // CODLOC: formulario.localEstoque,
      // CODFILIAL: formulario.filial,
      // NUMEROMOV: cotacao.numeroMov6,
      // OPERACAO: 'C',
      // USUARIO: usuarioLogin
    };

    let id_toast = toast.loading("Cancelando movimento");
    await axios.post(`https://api.pelicano.eng.br/api/totvs/cancela-movimento`,
      jsonCancelaMovimento, {
      timeout: 100000
    }
    ).then(async function (response) {
      let resp = response.data;
      if (resp) {
        toast.update(id_toast, { render: "Movimento 1106 cancelado com sucesso.", type: "success", isLoading: false, autoClose: 2000 });
      } else {
        const mensagemErro = "Aviso do RM ao cancelar movimento 1106: " + response.data.split("=======================================")[0];
        toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
      }
    }).catch(function (err) {
      console.log(err);
      toast.error(JSON.stringify(err.response.data));
      toast.update(id_toast, { render: "O cancelamento do movimento 1106 não foi efetivado, consulte o log.", type: "warning", isLoading: false, closeOnClick: true });
    })
  }


  const gerarMovimento = async (jsonEvolucao, numero_movimento, solicitacao) => {
    /*
      Método genérico que serve para evoluir os movimentos
     */
    let id_toast = toast.loading("Gerando movimento " + numero_movimento + "..");
    try {
      const response = await axios.post(`https://almox.pelicano.eng.br/php/movimento/faturamento/Faturamento.php`,
        jsonEvolucao, {
        timeout: 100000
      });
      let retorno = response.data;
      let retornoPrefixo = retorno.substring(0, 2);

      if (retornoPrefixo == "1;") {

        toast.update(id_toast, { render: "O movimento " + numero_movimento + " foi Salvo com sucesso no RM!", type: "success", isLoading: false, autoClose: 2000 });
        return true;
      } else {
        let substring = "=======================================";
        if (response.data.includes(substring)) {
          const mensagemErro = "Aviso do RM ao salvar movimento " + numero_movimento + ": " + response.data.split("=======================================")[0];
          toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
        }
        else {
          const mensagemErro = "O RM encontrou uma inconsistência ao salvar o movimento " + numero_movimento + ". Para mais informações, consulte o log";
          toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
        }
        return false;
      }
    } catch (err) {
      let substring = "=======================================";
      if (err.data.includes(substring)) {
        const mensagemErro = "Aviso do RM ao salvar movimento " + numero_movimento + ": " + err.data.split("=======================================")[0];
        toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
      }
      else {
        const mensagemErro = "O RM encontrou uma inconsistência ao salvar o movimento " + numero_movimento + ". Para mais informações, consulte o log";
        toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
      }

      return false;
    }
  }

  const handleAutocompleteEtapaARetornar = (event, newValue) => {
    setEtapaARetornar(newValue);
  };

  let limiteValorAgregado = null;
  const checaLimiteValorAgregado = async () => {
    /*
     Verifica se movimento de compra terá que passar pelo juridico
     */
    api.get('/valoresAgregados').then(res => {
      for (let i = 0; i < res.data.data.length; i++) {
        let obj = res.data.data[i];
        if (obj.identificador == "baixo_valor") {
          limiteValorAgregado = obj.valorMax;
        }
      }
    }).catch((err) => {
      console.error(err);
      toast.error(JSON.stringify(err.response.data));
    });
  }

  const alternarEstadoBotaoMapaCotacao = () => {
    //altera valor do botao que exibe o mapa de cotação
    setExibeMapaCotacao(!exibeMapaCotacao);
  };

  return (
    <Stack backgroundColor="#F5F5F5" height="100%" minHeight={"100vh"}>
      <Header mobile={mobile} notification />
      <Sidebar mobile={mobile} />

      <Stack
        ml={mobile ? "0px" : "250px"}
        mt="50px"
        flex={1}
        height={"calc(100% - 55px)"}
        p={mobile ? "20px" : "50px"}
        overflow="auto"
      >
        {/* Conteúdo da Tela */}
        <Typography level="h4">Movimento : {cotacao.numeroMov6} | Etapa atual: {etapaAtual}</Typography>

        <Stack flexDirection={"row"} justifyContent={"flex-end"} mt="10px" ml="auto">
          <ToggleButtonGroup
            value={valueTogglePagina}
            onChange={(event, newValue) => newValue ? setValueTogglePagina(newValue) : null}
          >
            <Button value="formulario" sx={valueTogglePagina == "formulario" ? { fontWeight: "bold" } : { fontWeight: "normal" }}>Formulário</Button>
            <Button value="anexo" sx={valueTogglePagina == "anexo" ? { fontWeight: "bold" } : { fontWeight: "normal" }}>Anexos</Button>
          </ToggleButtonGroup>
        </Stack>
        <Stack flexDirection={"row"} mt="10px" ml="auto">
          {numeroProcesso != "" ?
            <>
              {/* {etapaAtual != "Aprov. mcp1" ? : null */}
              <Button variant="plain" onClick={()=> setOpenModalLimite(true)} mr="20px">
                Acompanhar limite de gastos
              </Button>

              <Button variant="plain" onClick={alternarEstadoBotaoMapaCotacao} mr="20px">
                Mapa da cotação
              </Button>

              <Link fontSize="16px" onClick={() => navigate("/fluxoAprovacao", { state: { processo: numeroProcesso, origemChamada: "/aprovacaoCompras/", cotacao: cotacao.numeroMov6 } })} mr="20px">
                Fluxo de Aprovação
              </Link>
            </>
            : null
          }

        </Stack>

        {/* ABA FORMULARIO  */}
        {valueTogglePagina == "formulario" && (
          <>


            <Typography level="title-lg" mt="40px">
              Dados da Cotação
            </Typography>

            {cotacao ? (
              <>
                <Stack flexDirection={mobile ? "column" : "row"} mt="30px">
                  <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"}>
                    <FormLabel>Fornecedor Ganhador</FormLabel>
                    <Input type="text" value={cotacao.fornecedor} disabled />
                  </Stack>

                  <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Comprador</FormLabel>
                    <Input type="text" value={cotacao.comprador} disabled />
                  </Stack>
                </Stack>

                <Stack flexDirection={mobile ? "column" : "row"} mt="15px">
                  <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} >
                    <FormLabel>Código Cotação</FormLabel>
                    <Input type="text" value={cotacao.codCotacao} disabled />
                  </Stack>

                  <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Tipo Frete</FormLabel>
                    <Input type="text" value={cotacao.tipoFrete} disabled />
                  </Stack>

                  <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Condição de Pagamento</FormLabel>
                    <Input type="text" value={cotacao.condPagamento} disabled />
                  </Stack>

                  <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Prazo Entrega
                      <Tooltip arrow title={infoQtdDiasEntrega} color="primary" placement="top" variant={"solid"}>
                        <Button style={{ minHeight: "14px" }} height={14} variant="" color="primary"><FaCircleInfo size={13}></FaCircleInfo></Button>
                      </Tooltip>
                    </FormLabel>

                    <Input type="text" value={cotacao.prazo_entrega} disabled />
                  </Stack>

                  <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Data Efetiva Entrega</FormLabel>
                    <Input type="text" value={cotacao?.data_efetiva_entrega} disabled />
                  </Stack>

                  <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>N° 1.1.06</FormLabel>
                    <Input type="text" value={cotacao.numeroMov6} disabled />
                  </Stack>

                  <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>N° 1.1.08</FormLabel>
                    <Input type="text" value={cotacao.numeroMov8} disabled />
                  </Stack>

                </Stack>

                <Stack flexDirection={mobile ? "column" : "row"} mt="15px">
                  <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} mt={mobile ? "20px" : ""}>
                      <FormLabel>Transportadora</FormLabel>
                      <Input type="text" value={cotacao.transportadora} disabled />
                    </Stack>
                    
                    <Stack flex={1} minWidth={mobile ? "100%" : "80px"} width={mobile ? "100%" : "80px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                      <FormLabel>Tipo Pedido</FormLabel>
                      <Input type="text" value={cotacao.tipoPedido} disabled />
                    </Stack>

                    <Stack flex={1} minWidth={mobile ? "100%" : "80px"} width={mobile ? "100%" : "80px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                      <FormLabel>Cod Loc.</FormLabel>
                      <Input type="text" value={cotacao.codloc} disabled />
                    </Stack>

                </Stack>
              </>
            )
              : null
            }

            <Typography level="title-lg" mt="40px">
              Itens da Cotação
            </Typography>
            {/* 
            {etapaAtual == "Solicitante" && !osVinculada ?
              <Stack mt="10px" ml="auto">
                <Stack flexDirection={"row"}>
                  <Input value={nomeCodigoNovoItem} onChange={(event) => setNomeCodigoNovoItem(event.target.value)} placeholder="Código ou Descrição" sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} />
                  <Button size="sm" onClick={buscarItem} sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>Adicionar Item</Button>
                </Stack>
              </Stack>
              : null} */}

            <Stack mt="30px">
              {mobile ?
                <Stack>
                  {cotacao.itens.map((item, indice) => {
                    return (
                      <div>
                        <Stack p={"10px"} boxShadow="md" borderRadius={5} mb={"10px"} sx={{ userSelect: "none" }}>
                          <Stack flexDirection="row" alignItems="center" justifyContent={"space-between"}>
                            <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Código: </Typography>{item.codProduto}</Typography>

                          </Stack>
                          <Typography level="body-sm"><Typography level="title-md">Solicitação origem: </Typography>{item.solicitacao_id}</Typography>
                          <Typography level="body-sm"><Typography level="title-md">Item: </Typography>{indice + 1}</Typography>
                          <Typography level="body-sm"><Typography level="title-md">Descrição: </Typography>{item.descricao}</Typography>
                          <Typography level="body-sm"><Typography level="title-md">Saldo: </Typography>{item.estoque}</Typography>
                          <Typography level="body-sm"><Typography level="title-md">Qtd: </Typography>{item.quantidadeComprarRM}</Typography>
                          <Typography level="body-sm"><Typography level="title-md">Preço Unit: </Typography>{formatarMoeda(item.precoUnit)}</Typography>
                          <Typography level="body-sm"><Typography level="title-md">Preço Total: </Typography>{formatarMoeda(item.precoTotal)}</Typography>
                          {etapaAtual == "Solicitante" ? <Typography level="body-sm"><Typography level="title-md">Status: </Typography>{item.CODTMV == "1.1.01" ? "Pedido" : item.CODTMV == "1.1.03" ? "Baixado" : " - "}</Typography> : null}
                          <Stack level="body-sm" mt="20px">
                            <Stack flexDirection="row" alignItems="center">
                              <Button onClick={() => [setOpenModal(true), setIndiceItemConsultado(indice), setItemTemporario(cotacao.itens[indice])]} color="neutral" sx={osVinculada ? { width: "100%" } : { width: "50%", marginRight: "10px" }}>{osVinculada || numeroProcesso != "" ? "Consultar" : "Editar"}</Button>
                            </Stack>
                          </Stack>
                        </Stack>
                      </div>
                    );
                  })}
                </Stack>
                :
                <Table hoverRow stripe="even" noWrap aria-label="basic table">
                  <thead>
                    <tr>
                      {osVinculada && etapaAtual == "Solicitante" ? <th style={{ height: "25px", width: "25px" }}></th> : null}
                      <th style={{ width: "40px", height: "25px" }}>Item</th>
                      <th style={{ width: "80px", height: "25px" }}>Código</th>
                      <th style={{ width: "130px", height: "5px" }}>Solicitação origem</th>
                      <th style={{ height: "25px" }}>Descrição</th>
                      <th style={{ width: "80px", height: "25px" }}>Saldo</th>
                      <th style={{ width: "100px", height: "25px" }}>Qtd</th>
                      <th style={{ width: "100px", height: "25px" }}>Preço Unit.</th>
                      <th style={{ width: "100px", height: "25px" }}>Preço Total</th>
                      {etapaAtual == "Solicitante" ? <th style={{ width: "80px", height: "25px" }}>Status</th> : null}
                      <th style={{ width: "70px", height: "25px", textAlign: "center" }}>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cotacao.itens.map((item, index) =>

                      item.quantidadeComprarRM ?
                        <tr key={index}>
                          <td style={{ padding: "0 10px" }}>{parseInt(index + 1)}</td>
                          <td style={{ padding: "0 10px" }}>{item.codProduto}</td>
                          <td style={{ padding: "0 10px" }}>{item.solicitacao_id}</td>
                          <td style={{ padding: "0 10px" }}>{item.descricao}</td>
                          <td style={{ padding: "0 10px" }}>{item.estoque}</td>
                          <td style={{ padding: "0 10px" }}>{item.quantidadeComprarRM}</td>
                          <td style={{ padding: "0 10px" }}>{formatarMoeda(item.precoUnit)}</td>
                          <td style={{ padding: "0 10px" }}>{formatarMoeda(item.precoTotal)}</td>
                          {/* <td style={{ padding: "0 10px", display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center" }}>
                            <div className="hover">
                            </div>
                          </td> */}
                          <td style={{ padding: "0 10px", display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center" }}>
                            <div className="hover">
                              {((etapaAtual == "Solicitante") && (!osVinculada)) ?
                                <FaRegEdit onClick={() => [setOpenModal(true), setIndiceItemConsultado(index), setItemTemporario(cotacao.itens[index])]} color="#2974ac" size={22} />
                                :
                                <FaFolderOpen onClick={() => [setOpenModal(true), setIndiceItemConsultado(index), setItemTemporario(cotacao.itens[index])]} color="#2974ac" size={22} />
                              }
                            </div>
                            {((etapaAtual == "Solicitante") && (!osVinculada)) ?
                              <div onClick={() => [setIndiceItemConsultado(index), setDialogExclusao(true)]} className="hover" style={{ marginLeft: "10px" }}>
                                <FaRegTrashAlt color="#990000" size={22} />
                              </div>
                              : null}
                          </td>
                        </tr>
                        : null
                    )}
                  </tbody>
                </Table>
              }

              <Stack
                fontSize="22px"
                fontWeight={"bold"}
                alignItems={"end"}
                mt="20px"
                mr="0px"
                color="#333"
              >
                Total: {cotacao ? formatarMoeda(cotacao.totalCotacao) : ""}
              </Stack>
            </Stack>

            {/* Container de aprovação */}
            {permissaoAprovador ?
              <Stack flexDirection={"column"} boxShadow={mobile ? "" : "md"} padding={0} borderRadius={"10px"} mt="50px" sx={mobile ? {} : { backgroundColor: "#fff" }}>
                <Stack p={mobile ? 0 : 3} flexDirection={mobile ? "column" : "row"} justifyContent={mobile ? "flex-start" : "space-between"} alignItems={mobile ? "flex-start" : "flex-end"}>
                  <Stack>
                    <Typography level="title-lg">Decisão</Typography>
                    <ToggleButtonGroup
                      value={decisaoAprovacao}
                      onChange={(event, newValue) =>
                        [
                          setDecisaoAprovacao(newValue ? newValue : decisaoAprovacao),
                          rolarParaOCampo(newValue ? newValue : decisaoAprovacao),
                          definirStatus(newValue ? newValue : decisaoAprovacao),
                        ]
                      }
                    >
                      <Button value="Aprovado" style={decisaoAprovacao == "Aprovado" ? { backgroundColor: "#7cb342" } : {}}>
                        <Typography sx={decisaoAprovacao == "Aprovado" ? { color: "#eee" } : {}}>Aprovar</Typography>
                      </Button>
                      <Button value="Reprovado" style={decisaoAprovacao == "Reprovado" ? { backgroundColor: "#ff8f00" } : {}}>
                        <Typography sx={decisaoAprovacao == "Reprovado" ? { color: "#eee" } : {}}>Reprovar</Typography>
                      </Button>
                      <Button value="Cancelado" style={decisaoAprovacao == "Cancelado" ? { backgroundColor: "#f44336" } : {}}>
                        <Typography sx={decisaoAprovacao == "Cancelado" ? { color: "#eee" } : {}}>Cancelar</Typography>
                      </Button>
                    </ToggleButtonGroup>
                  </Stack>
                  <Stack width={mobile ? "100%" : "auto"}>

                    {/* Select de selecionar etapas anteriores, caso reprovacao (fase 2) */}
                    {etapasAnteriores.length > 0 && decisaoAprovacao == "Reprovado" ? (
                      <Stack m={"10px"}>
                        <Typography level="title-md">Retornar para Atividade:</Typography>
                        <Autocomplete options={etapasAnteriores} onChange={handleAutocompleteEtapaARetornar} value={etapaARetornar} />
                      </Stack>
                    ) : null}

                    <Button disabled={decisaoAprovacao == ""} sx={mobile ? { marginTop: "20px" } : {}} onClick={(e) => movimentarProcesso(null)}>Confirmar Decisão</Button>
                  </Stack>
                </Stack>
                <Stack p={mobile ? 0 : 3} flexDirection={mobile ? "column" : "row"} justifyContent={"space-between"} alignItems={"flex-end"}>
                  <Stack width={mobile ? "100%" : "50%"}>
                    {etapaAtual == "Aprov. mcpf" ?
                      <Stack flexDirection={"row"} alignItems={"center"}>
                        <GoLaw size={22} color="#222" />
                        <Checkbox disabled={cotacao.totalCotacao >= 500000 || possuiAdiantamento} checked={passarJuridico} onChange={e => setPassarJuridico(e.target.checked)} label="Passar pelo Jurídico" sx={{ marginLeft: "20px" }} />
                      </Stack>
                      : null
                    }
                    <Stack mt="30px" mr="30px">
                      <Typography level="title-md">Informações Adicionais</Typography>
                      <Textarea value={informacoesAd} onChange={event => setInformacoesAd(event.target.value)} minRows={3} sx={{ marginTop: "5px" }} placeholder="Descreva as informações adicionais" />
                    </Stack>
                  </Stack>
                  {decisaoAprovacao == "Reprovado" ?
                    <Stack mt="30px" width={mobile ? "100%" : "50%"}>
                      <Typography level="title-md">Motivo da Reprovação</Typography>
                      <Textarea value={motivoReprovacao} onChange={event => setMotivoReprovacao(event.target.value)} minRows={3} sx={{ marginTop: "5px", border: 1, borderColor: "#ff8f00" }} placeholder="Informe o motivo da reprovação" />
                    </Stack>
                    : null}
                  {decisaoAprovacao == "Cancelado" ?
                    <Stack mt="30px" width={mobile ? "100%" : "50%"}>
                      <Typography level="title-md">Motivo do Cancelamento</Typography>
                      <Textarea value={motivoCancelamento} onChange={event => setMotivoCancelamento(event.target.value)} minRows={3} sx={{ marginTop: "5px", border: 1, borderColor: "#f44336" }} placeholder="Informe o motivo do cancelamento" />
                    </Stack>
                    : null}
                </Stack>
              </Stack>
              : null
            }


            <InformacoesItem
              openModal={openModal}
              setOpenModal={setOpenModal}
              mobile={mobile}
              itemTemporario={itemTemporario}
              setItemTemporario={setItemTemporario}
              formulario={formulario}
              indiceItemConsultado={indiceItemConsultado}
              atualizarFormulario={atualizarFormulario}
              numeroProcesso={numeroProcesso}
              etapaAtual={etapaAtual}
              osVinculada={osVinculada}
              acrescentarItem={acrescentarItem}
            />

            {/* Modal de listagem de itens quando pesquisado por descricao */}
            <SelecaoItem
              openModalListaItem={openModalListaItem}
              setOpenModalListaItem={setOpenModalListaItem}
              itensEncontradosBusca={itensEncontradosBusca}
              novoItem={novoItem}
            />
          </>
        )}

        {cotacao.itens.length > 0 ?

          <ConfirmarExclusao
            dialogExclusao={dialogExclusao}
            setDialogExclusao={setDialogExclusao}
            mensagem={`Tem certeza que deseja excluir o item de código ${cotacao.itens[indiceItemConsultado].CODIGOPRD} ? Esta ação é irreversível`}
            excluirItem={excluirItem}
            indiceItemConsultado={indiceItemConsultado}
          />
          : null
        }

        {/* ABA DE ANEXO */}
        {valueTogglePagina == "anexo" && (
          <Anexos
            numeroProcesso={numeroProcesso}
            mobile={mobile}
            anexos={anexos}
            setUploadAnexos={setUploadAnexos}
            setAnexosSelecionados={setAnexosSelecionados}
            anexosSelecionados={anexosSelecionados}
            usuarioResponsavel={usuarioLogin}
            solicitacoesOrigem={solicitacoesOrigem}
            etapaAtual={etapaAtual}
            itensParaFornecedor={itensParaFornecedor}
            setItensParaFornecedor={setItensParaFornecedor}
          />
        )}

        {cotacao.id && token && (etapaAtual == "Aprov. mcp1") ?
          <DadosCotacao
            idCotacao={cotacao.id}
            token={token}
            numeroMov6={cotacao.numeroMov6}
            setDecisaoAprovacao={setDecisaoAprovacao}
            movimentarProcesso={movimentarProcesso}
            codCotacao={cotacao.codCotacao}
            etapaAtual={etapaAtual}
            permissaoAprovador={permissaoAprovador}
            setExibeMapaCotacao={setExibeMapaCotacao}
          />
          : null
        }
        {/* Exibir o mapa cotação para os todos os demais aprovadores (Não permitindo aprovar o mapa novamente ) */}
        {cotacao.id && token && usuarioPertenceObraCotacao && exibeMapaCotacao ?
          <DadosCotacao
            idCotacao={cotacao.id}
            token={token}
            numeroMov6={cotacao.numeroMov6}
            setDecisaoAprovacao={null}
            movimentarProcesso={null}
            codCotacao={cotacao.codCotacao}
            etapaAtual={etapaAtual}
            permissaoAprovador={false}
            setExibeMapaCotacao={setExibeMapaCotacao}
          />
          : null
        }

        <Modal open={openModalLimite}
          onClose={()=> setOpenModalLimite(false)}
        >
          <ModalDialog>
            <ModalClose />
            { cotacao.itens.length > 0 ?
                  <div style={{ padding: "10px"}}>
                  <Typography>Limite de gastos definido mensal/obra: </Typography> {formatarMoeda(cotacao.valorDeLimiteCadastrado)}
                  <Typography>Valor gasto até o momento: </Typography> { formatarMoeda(cotacao.valorSomadoObraMes)}
                  <Typography>Valor restante: </Typography>{ formatarMoeda(cotacao.valorDeLimiteCadastrado < cotacao.valorSomadoObraMes ? 0 : cotacao.valorDeLimiteCadastrado - cotacao.valorSomadoObraMes)}
                  </div>
                 :
                 null
            }
          </ModalDialog>
        </Modal>


      </Stack>
    </Stack>
  );
}
