import { toast } from 'react-toastify';
import { api } from '../../../../services/api';
import axios from 'axios';

const buscarProcessos = (filtros, processos, setProcessos, etapaAtual = "", setUltimaAtualizacao) => {
    const token = JSON.parse(localStorage.getItem('usuario')).token;

    if (!filtros.localEstoque) {
        toast.warning("Escolha um local de estoque!");
        return;
    }

    toast.loading("Buscando processos...");

    //Status fixado em "Em andamento"
    filtros.statusOs = 1;

    api.get(`/diligenciamento?dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}&statusOs=${filtros.statusOs}&localEstoque=${filtros.localEstoque}&codEquip=${filtros.codEquip == "" ? "%" : filtros.codEquip}&numOs=${filtros.numOs == "" ? "%" : filtros.numOs}&iniciarEm=${filtros.iniciarEm}&nomeResponsavel=${filtros.nomeResponsavel == "" ? "%" : filtros.nomeResponsavel}&atrasados=${filtros.atrasados}&etapaAtual=${etapaAtual}`,
        {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
    ).then(({ data }) => {
        // console.log(data);
        const processosFiltrados = etapaAtual == "" ?
            {
                listagem: data.data.listagem,
                Contagem: data.data.Contagem
            }
            :
            {
                listagem: data.data.listagem,
                Contagem: processos.Contagem
            }

        // console.log(processosFiltrados);
        setProcessos(processosFiltrados);

        //Salvando ultima data e hora de busca
        const date = new Date();
        const dataAtual = date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0') + "/" + date.getFullYear();
        const hora = date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0');
        const dataEHora = dataAtual + " as " + hora;

        if (setUltimaAtualizacao) {
            setUltimaAtualizacao(dataEHora);
        }

        //Limpando registros offline
        limparDadosSalvosOffline();

        //Salvando dados da busca offline
        salvarOffline(filtros, processosFiltrados, etapaAtual, dataEHora);

        toast.dismiss();
    }).catch((err) => {
        console.error(err);
        toast.dismiss();
    })
}

const buscarRastreamentos = (idmov, idprd, itensAbertos, setItensAbertos, setRastreamentos, etapaAtual, indiceDoItem) => {

    let rastreamentosOffline = obterRastreamentosOffline() || [];

    let novoRastreamento = true;
    let indiceRastreamentoBuscado = -1;
    for (let i = 0; i < rastreamentosOffline.length; i++) {
        if ([idmov + " - " + idprd] in rastreamentosOffline[i]) {
            if (rastreamentosOffline[i][idmov + " - " + idprd].indice == indiceDoItem) {
                novoRastreamento = false; // Encontrou a chave no objeto
                indiceRastreamentoBuscado = i;
            }
        }
    }
    if (novoRastreamento) {
        toast.loading("Buscando rastreamentos...");
        api.get(`/rastreamentoItem?IDMOV=${idmov}&IDPRD=${idprd}`)
            .then(function ({ data }) {
                const copiaArray = [...itensAbertos];
                copiaArray.push({ idPrd: idprd, indice: indiceDoItem });
                setItensAbertos(copiaArray);
                setRastreamentos(prevState => ({ ...prevState, [idmov + " - " + idprd]: { data: data, etapaAtual: etapaAtual, indice: indiceDoItem } }));

                console.log("ver o response de rastreamentoITEM: ", data);

                rastreamentosOffline.push({ [idmov + " - " + idprd]: { data: data, etapaAtual: etapaAtual, indice: indiceDoItem } });
                salvarRastreamentosOffline(rastreamentosOffline);

                toast.dismiss();
            }).catch(function (error) {
                console.log(error);
                toast.dismiss();
            })
    }
    else {
        setRastreamentos(prevState => ({ ...prevState, [idmov + " - " + idprd]: rastreamentosOffline[indiceRastreamentoBuscado][idmov + " - " + idprd] }));
        const copiaArray = [...itensAbertos];
        copiaArray.push({ idPrd: idprd, indice: indiceDoItem });
        setItensAbertos(copiaArray);
    }

}

const obterDadosOffline = () => {
    const data = {
        filtros: JSON.parse(localStorage.getItem('diligenciamento_filtros')),
        processos: JSON.parse(localStorage.getItem('diligenciamento_processos')),
        etapaAtual: JSON.parse(localStorage.getItem('diligenciamento_etapaAtual')),
        dataEHora: localStorage.getItem('diligenciamento_dataEHora'),
    }
    return data;
}

const salvarOffline = (filtros, processos, etapaAtual, dataEHora) => {

    localStorage.setItem('diligenciamento_filtros', JSON.stringify(filtros));
    localStorage.setItem('diligenciamento_processos', JSON.stringify(processos));
    localStorage.setItem('diligenciamento_etapaAtual', JSON.stringify(etapaAtual));
    localStorage.setItem('diligenciamento_dataEHora', dataEHora);

}

const salvarRastreamentosOffline = (rastreamentos) => {
    localStorage.setItem('diligenciamento_rastreamentos', JSON.stringify(rastreamentos));
}

const obterRastreamentosOffline = () => {
    return JSON.parse(localStorage.getItem('diligenciamento_rastreamentos'))
}

const limparDadosSalvosOffline = () => {
    localStorage.removeItem('diligenciamento_processos');
    localStorage.removeItem('diligenciamento_etapaAtual');
    localStorage.removeItem('diligenciamento_rastreamentos');
}

const obterTiposPedido = async () => {
    const token = JSON.parse(localStorage.getItem('usuario')).token;
    console.log({ token });
    const res = await api.get("/tiposPedido");
    return res.data;
}

export { buscarProcessos, buscarRastreamentos, obterDadosOffline, obterTiposPedido }