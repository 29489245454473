import React, { useState, useMemo } from "react";

import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Stack, Typography } from "@mui/joy";
import { dataEHora, formatarData } from "../../utils/datas";
import RastreamentosDiligenciamento from "../RastreamentosDiligenciamento";

//Métodos
import { buscarRastreamentos } from '../../modules/diligenciamento/pages/painel/metodos';

const coresStatus = {
    "EM ANDAMENTO": "#4697d8",
    "CONCLUÍDO": "#4c9855",
    "CANCELADO": "#9B111E",
}

const coresEtapas = {
    'Aprov. Técnico': "#333",
    'Aprov. Gerencial': "#e57373",
    'Aprov. Institucional': "#ff7043",
    'Análise Crítica Compras': "#007FFF",
    'Baixas': "#e53935",
    'Compras': "#00bcd4",
    'O.C Gerada': "#006064",
    'Aprov. mcp1': "#3ea2ed",
    'Aprov. mcp2': "#1976d2",
    'Aprov. mcpf': "#777",
    'Aprov. gestor adm suprimentos': "#c99100",
    'Aprov. gestor contrato/equipamentos': "#333",
    'Aprov. gestor corporativo equipamentos': "#9c27b0",
    'Aprov. diretoria equipamentos': "#009688",
    'Aprov. diretoria operações': "#f44336",
    'Aprov. diretoria suporte operacional': "#06c13b",
    'Aprov. diretoria geral': "#795548",
    'Em Cotação': "#f48fb1",
    'diligenciamento': "#f57c00",
    'finalizado': "#43a047", //Excluir
    'Disponivel': "#43a047",
    'Cancelado': "#9B111E"
}

const AccordionDiligenciamento = React.memo((props) => {

    const [itensAbertos, setItensAbertos] = useState([]);
    const [rastreamentos, setRastreamentos] = useState({});

    const removerItemAberto = (idPrd, indice) => {
        let copiaArray = [...itensAbertos];
        const indiceRemocao = itensAbertos.findIndex(item => item.indice == indice);
        if (indiceRemocao != -1) {
            copiaArray.splice(indiceRemocao, 1);
            setItensAbertos(copiaArray);
        }
    }

    return (
        <Stack borderRadius={"5px"} border="1px solid #ddd" mb="10px" p="0px">
            <AccordionGroup transition={{
                initial: "0.1s ease-out",
                expanded: "0.2s ease",
            }}>
                <Accordion>
                    <AccordionSummary>
                        <Stack flex={1} flexDirection={"row"}>
                            <Stack justifyContent={"center"} mr="15px" borderRight="1px solid #ddd" paddingRight="15px">
                                <Typography level="title-lg" sx={{ fontSize: 30 }}>{props.solicitacao}</Typography>
                            </Stack>
                            <Stack flex={1} mr="15px" borderRight="1px solid #ddd" paddingRight="15px">
                                <Typography sx={{ fontWeight: "bold" }}>Equipamento</Typography>
                                <Typography>{props.equipamento}</Typography>
                            </Stack>
                            <Stack mr="15px" borderRight="1px solid #ddd" paddingRight="15px">
                                <Typography sx={{ fontWeight: "bold" }}>Série</Typography>
                                <Typography>{props.serie}</Typography>
                            </Stack>
                            <Stack width="400px" mr="15px" borderRight="1px solid #ddd" paddingRight="15px">
                                <Typography sx={{ fontWeight: "bold" }}>Local de Estoque</Typography>
                                <Typography>{props.localEstoque}</Typography>
                            </Stack>
                            <Stack width="400px" mr="15px" borderRight="1px solid #ddd" paddingRight="15px">
                                <Typography sx={{ fontWeight: "bold" }}>Tipo de Pedido</Typography>
                                <Typography>{props.tipoPedido}</Typography>
                            </Stack>
                            <Stack mr="15px" borderRight="1px solid #ddd" paddingRight="15px">
                                <Typography sx={{ fontWeight: "bold" }}>Cod. Equip.</Typography>
                                <Typography>{props.codEquip}</Typography>
                            </Stack>
                            <Stack mr="15px" borderRight="1px solid #ddd" paddingRight="15px">
                                <Typography sx={{ fontWeight: "bold" }}>Data de Abertura</Typography>
                                <Typography>{formatarData(dataEHora(props.dataAbertura).data)}</Typography>
                            </Stack>
                            <Stack width="140px">
                                <Typography sx={{ fontWeight: "bold" }}>Status</Typography>
                                <Typography sx={{ color: coresStatus[props.status], fontWeight: "bold" }}>{props.status}</Typography>
                            </Stack>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AccordionGroup>
                            {props.itens && props.itens.map((item, indice) => {
                                const idmov = item?.numero?.numeroMov1;
                                return (
                                    <Accordion expanded={(itensAbertos.findIndex(itemAberto => itemAberto.indice == item.indice) != -1)} key={indice} sx={{ backgroundColor: "#e3f2fd", borderRadius: "10px" }}>
                                        <AccordionSummary onClick={() => (itensAbertos.findIndex(itemAberto => itemAberto.indice == item.indice) != -1) ? removerItemAberto(item.idPrd, item.indice) : buscarRastreamentos(idmov, item.idPrd, itensAbertos, setItensAbertos, setRastreamentos, item.ItensEtapaAtual, item.indice)}>
                                            <Stack flex={1} flexDirection={"row"} alignItems="center">
                                                <Stack paddingRight="10px" borderRight={"1px solid #ddd"}>
                                                    <Typography sx={{ borderRadius: "5px", backgroundColor: coresEtapas[item.ItensEtapaAtual], padding: "4px 10px", color: "#eee" }}>{item.ItensEtapaAtual}</Typography>
                                                </Stack>
                                                <Typography sx={{ marginLeft: "10px", padding: "0px 20px" }}>{item.codProduto + " - " + item.descricao}</Typography>
                                                <Typography sx={{ marginLeft: "10px", padding: "0px 20px" }}>Prev. Entrega: {props.dataNecessidade ? formatarData(dataEHora(props.dataNecessidade).data) : ""}</Typography>
                                            </Stack>
                                        </AccordionSummary>
                                        <AccordionDetails key={item.indice}>
                                            <Stack backgroundColor="#f2eecb" p="20px" borderRadius="10px">
                                                {rastreamentos[idmov + " - " + item.idPrd] ? <RastreamentosDiligenciamento rastreamentos={rastreamentos[idmov + " - " + item.idPrd]} indice={item.indice} etapaAtual={item.ItensEtapaAtual} /> : null}
                                            </Stack>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })}
                        </AccordionGroup>
                    </AccordionDetails>
                </Accordion>
            </AccordionGroup>
        </Stack>
    )
});

export default AccordionDiligenciamento;