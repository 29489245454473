import { useState, useEffect } from "react";

//componentes
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";
import Legenda from "../../../../components/Legenda";
//Utils
import { dataAtual, formatarData } from "../../../../utils/datas";
import { statusSolicitacao } from "../../../../utils/choices";

//Mui
import { Input, Select, Stack, Typography, Option, Button, Table, Switch, Link } from "@mui/joy";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";

//Material
import { Pagination } from '@mui/material/';
import useMediaQuery from '@mui/material/useMediaQuery';

import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

//API
import { api } from "../../../../services/api";

const dadosUsuarioLogado = { grupo: [] };
let idUsuario = 0;
let token = "";

//Dados do usuário logado no localstorage
const usuarioLogado = JSON.parse(localStorage.getItem('usuario'));

export default function CentralTarefas(props) {

    const navigate = useNavigate();

    //Verificando se o dispositivo é mobile
    const mobile = useMediaQuery('(max-width:1200px)');

    // const [dataInicio, setDataInicio] = useState(dataAtual());
    const [dataInicio, setDataInicio] = useState(dataAtual(-2));
    const [dataTermino, setDataTermino] = useState(dataAtual());
    const [status, setStatus] = useState(1);
    const [tarefas, setTarefas] = useState([]);
    const [paginas, setPaginas] = useState({
        paginaAtual: 1,
        numeroPaginas: 1
    });
    const [verTodasTarefas, setVerTodasTarefas] = useState(false);
    const [aprovadorLogado, setAprovadorLogado] = useState(false);
    const [solicitacaoBusca, setSolicitacaoBusca] = useState("");
    const [etapaAtualBuscada, setEtapaAtualBuscada] = useState(null);

    const [ordenaDataAbertura, setOrdenaDataAbertura] = useState(null);
    const [ordenaUltimaMovimentacao, setOrdenaUltimaMovimentacao] = useState(null);
    const [ordenaSolicitante, setOrdenaSolicitante] = useState(null);
    const [ordenaEtapaAtual, setOrdenaEtapaAtual] = useState(null);
    const [ordenaComprador, setOrdenaComprador] = useState(null);
    const [obraPesquisado, setObraPesquisado] = useState("");
    const [numMovPesquisado, setNumMovPesquisado] = useState("");

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("usuario")) != null) {
            dadosUsuarioLogado.nome = usuarioLogado.user.nome;
            dadosUsuarioLogado.usuario = usuarioLogado.user.login;
            dadosUsuarioLogado.obra = usuarioLogado.user.obra;
            dadosUsuarioLogado.grupo = usuarioLogado.grupo;
            dadosUsuarioLogado.id = usuarioLogado.user?.id;

            token = JSON.parse(localStorage.getItem('usuario')).token;
            idUsuario = JSON.parse(localStorage.getItem('usuario')).user.id;

            let permissoes = [];
            if (dadosUsuarioLogado.grupo.length > 0) {
                dadosUsuarioLogado.grupo.forEach((item) => {
                    permissoes.push(item.perfil);
                })
            }
            dadosUsuarioLogado.permissoes = permissoes;
        }
        buscarTarefas();

    }, []);

    //Recarrega a lista toda vez que clica nas datas:
    useEffect(() => {
        buscarTarefas(paginas.paginaAtual);
    }, [ordenaDataAbertura, ordenaUltimaMovimentacao, ordenaSolicitante, ordenaEtapaAtual, ordenaComprador]);


    const handleOrdenaDataAbertura = () => {
        // define estado da variavel ordenaDataAbertura (estados null, true ou false)
        if (ordenaDataAbertura == null){
            setOrdenaDataAbertura(true);
        } else if (ordenaDataAbertura == true){
            setOrdenaDataAbertura(false);
        }
        else if (ordenaDataAbertura == false){
            setOrdenaDataAbertura(null);
        }
        //ignorar a ordenacao de outras datas:
        setOrdenaUltimaMovimentacao(null);
    }

    const handleOrdenaUltimaMovimentacao = () => {
        // define estado da variavel ordenaUltimaMovimentacao (estados null, true ou false)
        if (ordenaUltimaMovimentacao == null){
            setOrdenaUltimaMovimentacao(true);
        } else if (ordenaUltimaMovimentacao == true){
            setOrdenaUltimaMovimentacao(false);
        }
        else if (ordenaUltimaMovimentacao == false){
            setOrdenaUltimaMovimentacao(null);
        }
        //ignorar a ordenacao de outras datas:
        setOrdenaDataAbertura(null);
    }

    const handleOrdenaSolicitante = () => {
        if (ordenaSolicitante == null){
            setOrdenaSolicitante(true);
        } else if (ordenaSolicitante == true){
            setOrdenaSolicitante(false);
        }
        else if (ordenaSolicitante == false){
            setOrdenaSolicitante(null);
        }
    }

    const handleOrdenaEtapaAtual = () => {
        if (ordenaEtapaAtual == null){
            setOrdenaEtapaAtual(true);
        } else if (ordenaEtapaAtual == true){
            setOrdenaEtapaAtual(false);
        }
        else if (ordenaEtapaAtual == false){
            setOrdenaEtapaAtual(null);
        }
    }

    const handleOrdenaComprador = () => {
        if (ordenaComprador == null){
            setOrdenaComprador(true);
        } else if (ordenaComprador == true){
            setOrdenaComprador(false);
        }
        else if (ordenaComprador == false){
            setOrdenaComprador(null);
        }
    }

    const buscarTarefas = (pagina = 1) => {
        //Definindo obra e etapa atual com base no session storage
        // const obra = dadosUsuarioLogado.grupos == null ? null : dadosUsuarioLogado.obra;
        let obra = JSON.parse(localStorage.getItem("usuario")).user.obra;
        //pegar grupos que o usuario esteja:
        let _grupos = usuarioLogado.grupo;
        if (_grupos) {
            _grupos.forEach((element, indice) => {
                if (obra.includes(element.obra) == false) {
                    obra = obra + "," + element.obra;
                }
            });
        }
        const perfil = dadosUsuarioLogado.grupo.length == 0 ? "Solicitante" : "Aprovador";
        const etapaAtual = dadosUsuarioLogado.grupo ? dadosUsuarioLogado.permissoes.length > 0 ? dadosUsuarioLogado.permissoes.map(permission => permission.replace(" ", "%20")).join(",") : "" : null;
        //Verificando se o usuário logado não possui nenhuma permissão, se isso ocorrer, ele é definido como solicitante, senão, aprovador
        setAprovadorLogado(etapaAtual != "");
        //Definindo a pagina atual da variav el de estado de acordo com a página passada por parametro para a função
        paginas.paginaAtual = pagina;
        //TODO: etapa atual: só pode ver a obra do usuário ou várias obras?

        let urlBuscarTarefas = "";
        if (etapaAtualBuscada != null){
            urlBuscarTarefas = `/solicitacoes?status=${status}&datasolicitacaoi=${dataInicio}&datasolicitacaof=${dataTermino}&usuario=${idUsuario}&page=${pagina}&todos=${verTodasTarefas}&perfil=${perfil}&etapaatual=${etapaAtualBuscada}`;
        } else {
            urlBuscarTarefas = `/solicitacoes?status=${status}&datasolicitacaoi=${dataInicio}&datasolicitacaof=${dataTermino}&usuario=${idUsuario}&page=${pagina}&todos=${verTodasTarefas}&perfil=${perfil}`;
        }

        //ordenar por comprador, etapaAtual e solicitante:
        if (ordenaSolicitante != null){
            urlBuscarTarefas += `&orderSolicitante=${ordenaSolicitante}`;
        }
        if (ordenaEtapaAtual != null){
            urlBuscarTarefas += `&orderEtapaAtual=${ordenaEtapaAtual}`;
        }
        if (ordenaComprador != null){
            urlBuscarTarefas += `&orderComprador=${ordenaComprador}`;
        }

        //Ordenar data criacao:
        if (ordenaDataAbertura != null){
            urlBuscarTarefas += `&orderDataAbertura=${ordenaDataAbertura}`;
        }
        //Ordenar ultima movimentacao
        else if (ordenaUltimaMovimentacao != null){
            urlBuscarTarefas += `&orderUltimaMovimentacao=${ordenaUltimaMovimentacao}`;
        }
        //filtros de Termos pesquisados:
        if(obraPesquisado != ""){
            urlBuscarTarefas += `&obraPesquisado=${obraPesquisado}`
        }
        if(numMovPesquisado != ""){
            urlBuscarTarefas += `&numMovPesquisado=${numMovPesquisado}`
        }

        api.get(urlBuscarTarefas,
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
        )
            .then((response) => {
                let tarefasDoBanco = [];
                if (response.data) {
                    const data = response.data;
                    data.data.forEach(tarefa => {
                        tarefasDoBanco.push(
                            {
                                solicitacao: tarefa.id,
                                solicitante: tarefa.nome,
                                etapaAtual: tarefa.etapaAtual,
                                dataAbertura: formatarData(tarefa.dataSolicitacao.split(" ")[0]),
                                ultimaMovimentacao: formatarData(tarefa.updated_at.split("T")[0]),
                                status: tarefa.status,
                                obra: tarefa.localEstoque,
                                decisao: tarefa.decisao,
                                tipoPedido: tarefa.tipoPedido,
                                comprador: tarefa.comprador || "N/A",
                                compradorID: tarefa.compradorID,
                                numeroPms: tarefa.numeroPms
                            }
                        );
                    });
                    setTarefas(tarefasDoBanco);
                    //Definindo a paginação
                    paginas.numeroPaginas = data.meta.last_page;
                }
            }).catch((error) => {
                console.log(error);
                if (error.response.data) {
                    if (error.response.data.message == "Unauthenticated.") {
                        localStorage.setItem("usuario", null);
                        navigate("/acessoNegado");
                    }
                    else {
                        toast.error("Ocorreu um erro ao obter as solicitações");
                        console.log(error.response.data.message);
                    }
                }
                toast.error(JSON.stringify(error.response.data));
            });
    }

    const alterarPagina = (event, value) => {
        paginas.paginaAtual = value;
        buscarTarefas(value);
    }


    const checarSolicitacaoEmProcessamento = (solicitacao) => {
        console.log("Checando solicitação em processamento...");

        api.get(`/solicitacoes/${solicitacao}`,
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            )
            .then(function ({ data }) {
                //solicitação em processamento, não pode ser acessada por 2 min:
                if (data.data.emProcessamento){
                    console.log("em processamento. wait!");
                    toast.warning("Esta solicitação está em processamento. Por favor, Aguarde até que ela fique disponível.");
                } else {
                    console.log("pode seguir na solicitacao.");
                    navigate("/solicitacao/" + solicitacao, { state: { processo: solicitacao } });
                    //navigate("/solicitacao/" + item.solicitacao, { state: { processo: item.solicitacao } });
                }
            })
            .catch(function (err) {
              console.log(err);
              toast.error(JSON.stringify(err.response.data));
            });

    }

    return (
        <Stack backgroundColor="#F5F5F5" height="100%" minHeight={"100vh"}>
            <Header notification mobile={mobile} />
            <Sidebar mobile={mobile} />

            <Stack ml={mobile ? "0px" : "280px"} mt={"50px"} flex={1} height={mobile ? "100%" : "calc(100% - 55px)"} p={mobile ? "20px" : "50px"} overflow="auto">
                {/* Conteúdo da Tela */}
                <Typography level="h4">Central de Requisições</Typography>
                <Stack ml={mobile ? "0px" : "20px"}>

                    <Legenda mobile={mobile} />

                    <Stack rowGap={"1rem"} flexWrap={"wrap"} flexDirection={mobile ? "column" : "row"} mt={mobile ? "20px" : "30px"} alignItems={mobile ? "flex-start" : "center"} justifyContent={"space-between"}>
                        <Stack width={mobile ? "100%" : "unset"}  flexDirection={mobile ? "column" : "row"}>
                            {/* <Stack mb={mobile ? "40px" : "0px"} mr={"30px"}>
                                <Typography level="body-sm">N° da Solicitação</Typography>
                                <Stack>
                                    <Input value={solicitacaoBusca} onChange={e => setSolicitacaoBusca(e.target.value)} sx={{ width: "120px" }} />
                                </Stack>
                            </Stack> */}
                            <Stack>
                                <Typography level="body-sm">Criadas a partir de:</Typography>
                                <Input type="date" value={dataInicio} onChange={(event) => setDataInicio(event.target.value)} />
                            </Stack>
                            <Stack ml={mobile ? "0px" : "30px"} mt={mobile ? "10px" : "0px"}>
                                <Typography level="body-sm">Até esta data:</Typography>
                                <Input type="date" value={dataTermino} onChange={(event) => setDataTermino(event.target.value)} />
                            </Stack>
                            <Stack ml={mobile ? "0px" : "30px"} mt={mobile ? "10px" : "0px"} mb={mobile ? "20px" : "0px"}>
                                <Typography level="body-sm">Status</Typography>
                                <Select fontSize={12} value={status} onChange={(event, newValue) => setStatus(newValue)} placeholder="Status da Solicitação">
                                    <Option value={1}>Em Andamento</Option>
                                    <Option value={2}>Cancelado</Option>
                                    <Option value={3}>Concluído</Option>
                                </Select>
                            </Stack>
                            <Stack ml={mobile ? "0px" : "30px"} mt={mobile ? "10px" : "0px"} mb={mobile ? "20px" : "0px"}>
                                <Typography level="body-sm">Etapa Atual</Typography>
                                <Select fontSize={12} value={etapaAtualBuscada} onChange={(event, newValue) => setEtapaAtualBuscada(newValue)} placeholder="Selecionar">
                                    <Option value={null} selected>Todas</Option>
                                    <Option value={"Solicitante"}>Solicitante</Option>
                                    <Option value={"Aprov. Técnico"}>Aprov. Técnico</Option>
                                    <Option value={"Aprov. Gerencial"}>Aprov. Gerencial</Option>
                                    <Option value={"Aprov. Institucional"}>Aprov. Institucional</Option>
                                    <Option value={"Análise Crítica Compras"}>Análise Crítica Compras</Option>
                                    <Option value={"Compras"}>Compras</Option>
                                </Select>
                            </Stack>
                            <Stack ml={mobile ? "0px" : "30px"} mt={mobile ? "10px" : "0px"} mb={mobile ? "20px" : "0px"}>
                                <Typography level="body-sm">N° Movimento:</Typography>
                                <Input sx={{ width: mobile? "unset" : "120px" }} type="text" value={numMovPesquisado} onChange={(event) => setNumMovPesquisado(event.target.value)} />
                            </Stack>
                            <Stack ml={mobile ? "0px" : "30px"} mt={mobile ? "10px" : "0px"} mb={mobile ? "20px" : "0px"}>
                                <Typography level="body-sm">Filtrar por:</Typography>
                                <Input sx={{ width: mobile? "unset" : "120px" }} type="text" value={obraPesquisado} placeholder="Nº Obra" onChange={(event) => setObraPesquisado(event.target.value)} />
                            </Stack>
                            <Stack ml={mobile ? "0px" : "30px"} mb={mobile ? "40px" : "0px"}>
                                <Typography level="body-sm">Ver todos</Typography>
                                <Stack alignSelf={"flex-start"} justifyContent="center" flex={mobile ? null : 1}>
                                    <Switch
                                        checked={verTodasTarefas}
                                        onChange={(event) => setVerTodasTarefas(event.target.checked)}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack flexDirection={"row"} justifyContent={mobile ? "space-between" : "flex-end"} width={mobile ? "100%" : "auto"}>
                            <Button onClick={() => navigate("/solicitacao")} color="primary" sx={{ marginRight: "20px", width: "170px" }}>NOVA REQUISIÇÃO</Button>
                            <Button onClick={() => buscarTarefas()} color="neutral">ATUALIZAR</Button>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack mt="30px">
                    {mobile ?
                        <Stack>
                            {tarefas.map(function (item, indice) {
                                const etapaAtualDoProcesso = item.etapaAtual != "Aprov. Tecnico" ? item.etapaAtual : "Aprov. Técnico";
                                let corDaLinha = "";

                                if (dadosUsuarioLogado.permissoes) {

                                    const obraEPermissao = dadosUsuarioLogado.grupo.filter(grupo => grupo.perfil == etapaAtualDoProcesso
                                        && grupo.obra == item.obra
                                        && grupo.tipoPedido.includes(item.tipoPedido)
                                    ).length > 0;
                                    if (
                                        ((dadosUsuarioLogado.permissoes.indexOf(etapaAtualDoProcesso) != -1) && (etapaAtualDoProcesso != "Solicitante") && (etapaAtualDoProcesso != "Compras") && obraEPermissao) ||
                                        (item.solicitante == dadosUsuarioLogado.nome && item.etapaAtual == "Solicitante") ||
                                        (item.etapaAtual == "Compras" && item.compradorID == dadosUsuarioLogado.id)
                                    ) {
                                        corDaLinha = "#ffe082";
                                    }
                                }

                                if ((item.decisao == "Reprovado") && (item.solicitante == dadosUsuarioLogado.nome && item.etapaAtual == "Solicitante")) {
                                    corDaLinha = "#ef9a9a";
                                }

                                return (
                                    <div className="hover" key={indice}>
                                        <Stack onClick={() => checarSolicitacaoEmProcessamento(item.solicitacao)} p={"10px"} boxShadow="md" borderRadius={5} mb={"20px"} sx={{ userSelect: "none" }} style={{ backgroundColor: corDaLinha }}>
                                            <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Solicitação: </Typography>{item.solicitacao}</Typography>
                                            <Typography level="body-sm" sx={{ color: "#222" }}><Typography level="title-md" sx={{ color: "#444" }}>Solicitante: </Typography>{item.solicitante}</Typography>
                                            <Typography level="body-sm" sx={{ color: "#222" }}><Typography level="title-md" sx={{ color: "#444" }}>Etapa Atual: </Typography>{item.etapaAtual}</Typography>
                                            <Typography level="body-sm" sx={{ color: "#222" }}><Typography level="title-md" sx={{ color: "#444" }}>Comprador: </Typography>{item.comprador}</Typography>
                                            <Typography level="body-sm" sx={{ color: "#222" }}><Typography level="title-md" sx={{ color: "#444" }}>N° Movimento.: </Typography>{item.numeroPms}</Typography>
                                            <Typography level="body-sm" sx={{ color: "#222" }}><Typography level="title-md" sx={{ color: "#444" }}>Data Abertura: </Typography>{item.dataAbertura}</Typography>
                                            <Typography level="body-sm" sx={{ color: "#222" }}><Typography level="title-md" sx={{ color: "#444" }}>Ultima Movimetação: </Typography>{item.ultimaMovimentacao}</Typography>
                                            <Typography level="body-sm" sx={{ color: "#222" }}><Typography level="title-md" sx={{ color: "#444" }}>Status: </Typography>{statusSolicitacao(item.status)}</Typography>
                                        </Stack>
                                    </div>
                                )
                            })}
                        </Stack>
                        :
                        <Stack>
                            <Table hoverRow noWrap>
                                <thead>
                                    <tr>
                                        <th style={{ height: "25px", minWidth: "100px" }}>Solicitação</th>
                                        <th style={{ height: "25px", minWidth: "300px", flexGrow: 2 }}><Link onClick={()=>handleOrdenaSolicitante()}>Nome do Solicitante {ordenaSolicitante != null ? ordenaSolicitante?<FaArrowDown size={10} style={{marginLeft:"2px"}}/>:<FaArrowUp size={10} style={{marginLeft:"2px"}}/>: ""} </Link></th>
                                        <th style={{ height: "25px", minWidth: "100px", flexGrow: 1 }}><Link onClick={()=>handleOrdenaEtapaAtual()}>Etapa Atual {ordenaEtapaAtual != null ? ordenaEtapaAtual?<FaArrowDown size={10} style={{marginLeft:"2px"}}/>:<FaArrowUp size={10} style={{marginLeft:"2px"}}/>: ""} </Link></th>
                                        <th style={{ height: "25px", minWidth: "300px", flexGrow: 3 }}><Link onClick={()=>handleOrdenaComprador()}>Comprador {ordenaComprador != null ? ordenaComprador?<FaArrowDown size={10} style={{marginLeft:"2px"}}/>:<FaArrowUp size={10} style={{marginLeft:"2px"}}/>: ""} </Link></th>
                                        <th style={{ height: "25px", minWidth: "80px" }}>Nº Movimento</th>
                                        <th style={{ height: "25px", minWidth: "90px" }}><Link onClick={()=>handleOrdenaDataAbertura()}>Data Abertura {ordenaDataAbertura != null ? ordenaDataAbertura?<FaArrowDown size={10} style={{marginLeft:"2px"}}/>:<FaArrowUp size={10} style={{marginLeft:"2px"}}/>: ""} </Link></th>
                                        <th style={{ height: "25px", minWidth: "120px" }}><Link onClick={()=>handleOrdenaUltimaMovimentacao()}>Ultima Movimentação {ordenaUltimaMovimentacao != null ? ordenaUltimaMovimentacao?<FaArrowDown size={10} style={{marginLeft:"2px"}}/>:<FaArrowUp size={10} style={{marginLeft:"2px"}}/>: ""} </Link></th>
                                        <th style={{ height: "25px", minWidth: "80px" }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tarefas.map(function (item, indice) {
                                        const etapaAtualDoProcesso = item.etapaAtual != "Aprov. Tecnico" ? item.etapaAtual : "Aprov. Técnico";
                                        let corDaLinha = "";

                                        // console.log(item);

                                        if (dadosUsuarioLogado.permissoes) {
                                            
                                            const obraEPermissao = dadosUsuarioLogado.grupo.filter(grupo => grupo.perfil == etapaAtualDoProcesso
                                                && grupo.obra == item.obra
                                                && grupo.tipoPedido.includes(item.tipoPedido)
                                            ).length > 0;
                                            if (
                                                ((dadosUsuarioLogado.permissoes.indexOf(etapaAtualDoProcesso) != -1) && (etapaAtualDoProcesso != "Solicitante") && (etapaAtualDoProcesso != "Compras") && obraEPermissao) ||
                                                (item.solicitante == dadosUsuarioLogado.nome && item.etapaAtual == "Solicitante") ||
                                                (item.etapaAtual == "Compras" && item.compradorID == dadosUsuarioLogado.id)
                                            ) {
                                                corDaLinha = "#ffe082";
                                            }
                                        }

                                        if ((item.decisao == "Reprovado") && (item.solicitante == dadosUsuarioLogado.nome && item.etapaAtual == "Solicitante")) {
                                            corDaLinha = "#ef9a9a";
                                        }

                                        return (
                                            <tr key={indice} className="hover" onClick={() => checarSolicitacaoEmProcessamento(item.solicitacao)} style={{ backgroundColor: corDaLinha }} >
                                                <td style={{ padding: "0 10px" }}>{item.solicitacao}</td>
                                                <td style={{ padding: "0 10px" }}>{item.solicitante}</td>
                                                <td style={{ padding: "0 10px" }}>{item.etapaAtual}</td>
                                                <td style={{ padding: "0 10px" }}>{item.comprador}</td>
                                                <td style={{ padding: "0 10px" }}>{item.numeroPms}</td>
                                                <td style={{ padding: "0 10px" }}>{item.dataAbertura}</td>
                                                <td style={{ padding: "0 10px" }}>{item.ultimaMovimentacao}</td>
                                                <td style={{ padding: "0 10px", fontWeight: "bold" }}>{statusSolicitacao(item.status)}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Stack>
                    }
                    <Stack mt={3} alignItems={mobile ? "center" : "flex-end"}>
                        <Pagination
                            onChange={alterarPagina}
                            count={paginas.numeroPaginas}
                            page={paginas.paginaAtual}
                            hideNextButton={paginas.paginaAtual == paginas.numeroPaginas}
                            hidePrevButton={paginas.paginaAtual == 1}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}