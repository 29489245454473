import { useState, useEffect } from "react";
import { Modal, Sheet, ModalClose, Typography, Stack, FormLabel, Input, Checkbox, Button, Link, Textarea } from "@mui/joy";
import { toast } from 'react-toastify';

//Componentes
import SaldoDosItens from "../SaldoDosItens";
import UltimasCompras from "../UltimasCompras";

//Apis do RM
import { RM } from '../../services/api';
import realizaConsulta from "../../services/rm";

export default function MovimentacoesItem(props) {

    const novoItem = Object.keys(props.itemTemporario).length > 0;

    const [saldosDoItem, setSaldosDoItem] = useState([]);
    const [ultimasCompras, setUltimasCompras] = useState({});
    const [openModalInfoItem, setOpenModalInfoItem] = useState(false); //Modal de informacoes dos saldos em cada local
    const [modalUltimasCompras, setModalUltimasCompras] = useState(false);

    const obterTodosSaldos = (codigoprd) => {
        const id = toast.loading("Buscando todos os saldos");
        realizaConsulta('01.001.016', { params: { 'CODIGOPRD_S': codigoprd }})
            .then(function ({ data }) {
                let locaisComSaldo = [];
                data.forEach(saldo => {
                    if (saldo.SALDOFISICO2 > 0) {
                        locaisComSaldo.push(saldo);
                    }
                });
                if (locaisComSaldo.length > 0) {
                    setSaldosDoItem(locaisComSaldo);
                    setOpenModalInfoItem(true);
                    toast.update(id, { render: "Saldos obtido com sucesso", type: "success", isLoading: false, autoClose: 1000 });
                }
                else {
                    toast.update(id, { render: "O item não possui saldos", type: "warning", isLoading: false, autoClose: 2000 });
                }
            }).catch(function (err) {
                toast.update(id, { render: "Erro ao obter todos os saldos", type: "error", isLoading: false, autoClose: 2000 });
                console.log(err);
                toast.error(JSON.stringify(err.response.data));
            });
    }

    const obterUltimasCompras = (codigoprd) => {
        const id = toast.loading("Buscando ultimas compras");
        realizaConsulta('01.001.017', { params: { CODIGOPRD: codigoprd } })
            .then(function ({ data }) {
                toast.dismiss();
                setUltimasCompras(data[0]);
                setModalUltimasCompras(true);
            }).catch(function (err) {
                toast.update(id, { render: "Erro ao obter ultimas compras", type: "error", isLoading: false, autoClose: 2000 });
                toast.error(JSON.stringify(err.response.data));
            });
    }

    //Definindo se os checkbox estarão desabilitados
    const grupos = JSON.parse(localStorage.getItem('usuario')).grupo;
    //TODO: são vários grupos agora:
    // const perfil = grupos.length > 0 ? grupos[0].perfil : "";
    const perfil = grupos.length > 0 ? grupos.map(grupo => grupo.perfil) : [];
    const checkboxDesabilitado = !((
        ((props.formulario.tipoPedido == "007") && (props.formulario.etapaAtual == "Aprov. Institucional") && (perfil.includes(props.formulario.etapaAtual))) ||
        ((props.formulario.tipoPedido != "007") && (props.formulario.etapaAtual == "Aprov. Gerencial") && (perfil.includes(props.formulario.etapaAtual))) ||
        ((props.formulario.etapaAtual == "Solicitante"))
    ));

    return (
        <Stack>
            {Object.keys(props.itemTemporario).length > 0 ?
                <Modal
                    aria-labelledby="modal-title"
                    aria-describedby="modal-desc"
                    open={props.openModal}
                    onClose={() => props.setOpenModal(false)}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        outline: "none",
                    }}
                >
                    <Sheet
                        variant="outlined"
                        sx={{
                            width: "85%",
                            maxWidth: props.mobile ? "100%" : "1000px",
                            height: props.mobile ? "auto" : "60%",
                            borderRadius: 'md',
                            p: props.mobile ? 2 : 3,
                            boxShadow: 'lg',
                            outline: "none",
                        }}
                    >
                        <ModalClose
                            variant="outlined"
                            sx={{
                                top: "calc(-1/4 * var(--IconButton-size))",
                                right: "calc(-1/4 * var(--IconButton-size))",
                                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                                borderRadius: "50%",
                                bgcolor: "background.surface",
                            }}
                        />
                        <Typography
                            component="h2"
                            id="modal-title"
                            level="h4"
                            textColor="inherit"
                            fontWeight="lg"
                            mb={1}
                        >
                            Movimentações do Item
                        </Typography>

                        <Stack display={"flex"} flexDirection="row" mt="20px" sx={props.mobile ? { overflow: "auto", height: "70vh" } : { overflow: "auto", height: "90%" }}>

                            {props.itemTemporario.movimentado ? props.itemTemporario.movimentado.map((movimento, indice) => {
                                return (
                                    <Stack minWidth={props.mobile ? "80%" : "300px"} key={indice} mr="20px" boxShadow={"md"} border="1px solid #ddd" padding="20px" borderRadius={"10px"} mb="20px" sx={{ overflow: "auto" }}>
                                        <Stack mb="10px">
                                            <Typography sx={{ fontWeight: "bold" }}>Código</Typography>
                                            <Typography>{movimento.CODIGOPRD}</Typography>
                                        </Stack>
                                        <Stack mb="10px">
                                            <Typography sx={{ fontWeight: "bold" }}>Descrição</Typography>
                                            <Typography>{movimento.NOMEFANTASIA}</Typography>
                                        </Stack>
                                        <Stack mb="10px">
                                            <Typography sx={{ fontWeight: "bold" }}>N° Sequencial</Typography>
                                            <Typography>{movimento.NSEQITMMOV}</Typography>
                                        </Stack>
                                        <Stack mb="10px">
                                            <Typography sx={{ fontWeight: "bold" }}>Status</Typography>
                                            <Typography>{movimento.STATUS}</Typography>
                                        </Stack>
                                        <Stack mb="10px">
                                            <Typography sx={{ fontWeight: "bold" }}>Numero Mov</Typography>
                                            <Typography>{movimento.NUMEROMOV}</Typography>
                                        </Stack>
                                        <Stack mb="10px">
                                            <Typography sx={{ fontWeight: "bold" }}>Quantidade</Typography>
                                            <Typography>{movimento.QUANTIDADEARECEBER}</Typography>
                                        </Stack>
                                        <Stack mb="10px">
                                            <Typography sx={{ fontWeight: "bold" }}>Histórico</Typography>
                                            <Typography>{movimento.HISTORICO_ITEM}</Typography>
                                        </Stack>
                                    </Stack>
                                );
                            }) : null}
                        </Stack>

                        {((props.etapaAtual == "Solicitante" && !props.osVinculada) || (props.formulario.tipoPedido == "007" && props.etapaAtual == "Aprov. Institucional") || (props.formulario.tipoPedido != "007" && props.etapaAtual == "Aprov. Gerencial")) ?
                            <Button
                                variant="solid"
                                size="md"
                                color="primary"
                                aria-label="Adicionar"
                                sx={{
                                    m: "auto",
                                    mb: "20px",
                                    alignSelf: "center",
                                    fontWeight: 600,
                                }}
                                style={props.mobile ? { marginTop: "15px" } : {}}
                                disabled={!(props.itemTemporario.quantidadeProduto && parseInt(props.itemTemporario.quantidadeProduto, 10) >= 1)}
                                // disabled={((props.itemTemporario != {} && props.itemTemporario.quantidadeProduto == "" || props.itemTemporario.quantidadeProduto == "-"))}
                                onClick={() => props.acrescentarItem()}
                            >
                                Confirmar
                            </Button>
                            : null}
                    </Sheet>
                </Modal>
                : null
            }

            {/* Modal saldo dos itens */}
            <SaldoDosItens
                openModalInfoItem={openModalInfoItem}
                setOpenModalInfoItem={setOpenModalInfoItem}
                saldosDoItem={saldosDoItem}
            />

            <UltimasCompras
                open={modalUltimasCompras}
                setOpen={setModalUltimasCompras}
                data={ultimasCompras}
            />
        </Stack>
    );
}