import { useState, useEffect, memo } from "react";
import { Button, Card, Checkbox, Input, Link, Option, Radio, RadioGroup, Select, Sheet, Stack, Tab, TabList, TabPanel, Tabs, Typography } from "@mui/joy";
import { IoSearch } from "react-icons/io5";

//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';

//Icones
import { IoAlertCircleSharp } from "react-icons/io5";

//Servicos
import { obterPermissoesUsuario } from "../../../../services/permissoes";

//Utils
import { dataAtual } from '../../../../utils/datas';

import { obterProcessosPorEtapaDeAprovacao, obterEntregasEmDiaEAtrasados, obterSolicitacoesLocaisEstoque, obterMediasAberturasEFechamentos } from './metodos';

//Métodos
// import { buscarProcessos, obterDadosOffline } from './metodos';
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//Chart js
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement } from 'chart.js';
import { Bar, Doughnut, Line } from 'react-chartjs-2';

import 'chart.js/auto'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PointElement
);

// const obterData = (mesesAtras = 0) => {
//     const date = new Date();
//     if (mesesAtras > 0) {
//         date.setMonth((date.getMonth() + 1) - mesesAtras);
//         if (date.getMonth() == 0) {
//             date.setMonth(11);
//             date.setFullYear(date.getFullYear() - 1);
//             return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, "0") + '-' + date.getDate().toString().padStart(2, "0");
//         }
//         return date.getFullYear() + '-' + (date.getMonth()).toString().padStart(2, "0") + '-' + date.getDate().toString().padStart(2, "0");
//     }
//     return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, "0") + '-' + date.getDate().toString().padStart(2, "0");
// }

// const formatarCodEquip = (codEquip) => {
//     if (codEquip.length == 8 && !isNaN(codEquip)) {
//         const stringFormatada = codEquip.replace(/(\d{1})(\d{3})(\d{4})/, '$1.$2.$3');
//         return stringFormatada;
//     }
//     return codEquip;
// }

let codigoLocalEstoque = {}

export default function Dashboard() {

    let usuarioLogado = (JSON.parse(localStorage.getItem('usuario')).user.login);
    const navigate = useNavigate();

    const mobile = useMediaQuery('(max-width:1200px)');

    const [anosDeFiltragem, setAnosDeFiltragem] = useState([]);
    const [labels, setLabels] = useState(['Aprov. Técnico', 'Aprov. Gerencial', 'Aprov. Institucional', 'Baixas', 'Compras', 'Em Cotação', 'Aprov. mcp1', 'Aprov. mcp2', 'Aprov. mcpf', 'Aprov. gestor adm suprimentos', 'Aprov. gestor contrato/equipamentos', 'Aprov. gestor corporativo equipamentos', 'Aprov. diretoria equipamentos', 'Aprov. diretoria operações', 'Aprov. diretoria suporte operacional', 'Aprov. diretoria geral', 'Diligenciamento', 'Disponivel', 'Cancelado']);
    const [labelsAberturaEFechamento, setLabelsAberturaEFechamento] = useState(['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']);
    const [labelsLocalEstoque, setLabelsLocalEstoque] = useState(['001.00 - Almoxarifado central', '001.01 - Patio externo matriz', '001.04 - Fazenda Rosa', '001.05 - Fazenda Aça', '265.00 - Obra 265', '273.00 - Obra 273', '288.00 - Obra 288']);
    const [locaisEstoque, setLocaisEstoque] = useState([]);
    const [filtrosEtapaAprovacao, setFiltrosEtapaAprovacao] = useState({ dataInicial: dataAtual(-1), dataFinal: dataAtual(), localEstoque: "" });
    const [filtrosEntregaEmDiaEAtrasado, setFiltrosEntregaEmDiaEAtrasado] = useState({ dataInicial: dataAtual(-1), dataFinal: dataAtual() });
    const [filtrosSolicitacoesLocalEstoque, setFiltrosSolicitacoesLocalEstoque] = useState({ dataInicial: dataAtual(-1), dataFinal: dataAtual() });
    const [filtrosMediaAberturaFechamento, setFiltrosMediaAberturasFechamentos] = useState({ ano: new Date().getFullYear() });
    const [dadosEtapaAprovacao, setDadosEtapaAprovacao] = useState({ os: {}, pms: {} });
    const [dadosEntregasEmDiaEAtrasado, setDadosEntregasEmDiaEAtrasado] = useState({ "Em dia": 0, "Atrasado": 0 });
    const [dadosSolicitacoesLocalEstoque, setDadosSolicitacoesLocalEstoque] = useState({ "291.00": 0, "001.00": 0 });
    const [dadosAberturasEFechamentos, setDadosAberturasEFechamentos] = useState({});

    useEffect(() => {

        let anos = [];
        for (let i = 2023; i <= new Date().getFullYear(); i++) {
            anos.push(i);
        }

        setAnosDeFiltragem(anos);

        obterPermissoesUsuario(usuarioLogado).then(response => {
            setLocaisEstoque(response[1]);
            response[1].forEach(localEstoque => {
                codigoLocalEstoque[localEstoque.split(" - ")[0]] = localEstoque
            });
            setFiltrosEtapaAprovacao(prevState => ({ ...prevState, localEstoque: response[1][0].split(" - ")[0] }))

            //Obtendo dados
            filtrosEtapaAprovacao.localEstoque = response[1][0].split(" - ")[0];
            obterProcessosPorEtapaDeAprovacao(filtrosEtapaAprovacao, setDadosEtapaAprovacao);
            obterEntregasEmDiaEAtrasados(filtrosEtapaAprovacao, setDadosEntregasEmDiaEAtrasado);
            obterSolicitacoesLocaisEstoque(filtrosEtapaAprovacao, setDadosSolicitacoesLocalEstoque);
            obterMediasAberturasEFechamentos(filtrosMediaAberturaFechamento, setDadosAberturasEFechamentos);
        });

    }, []);

    //Dados para os gráficos

    //Gráfico de processos por etapa de aprovação
    const dataAtendimentoSemanais = {
        labels,
        datasets: [
            {
                label: 'O.S',
                data: labels.map((label) => { return dadosEtapaAprovacao.os[label] }),
                backgroundColor: 'rgba(53, 162, 235, 0.7)',
            },
            {
                label: 'PMS',
                data: labels.map((label) => { return dadosEtapaAprovacao.pms[label] }),
                backgroundColor: '#FF6384',
            }
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                // text: 'Atendimentos Semanais',
            },
        },

    };

    //Gráfico de atendimentos em dia / atrasados
    const data = {
        labels: ['Em Dia', 'Em Atraso'],
        datasets: [
            {
                data: [dadosEntregasEmDiaEAtrasado["Em dia"], dadosEntregasEmDiaEAtrasado["Atrasado"]],
                backgroundColor: ['#36A2EB', '#FF6384'],
                hoverBackgroundColor: ['#36A2EB', '#FF6384'],
            },
        ],
    };

    //Gráfico de solicitações por local de estoque
    const dataLocalEstoque = {
        labels: Object.keys(dadosSolicitacoesLocalEstoque),
        datasets: [
            {
                label: "Solicitações",
                backgroundColor: 'rgba(53, 162, 235, 0.7)',
                borderColor: 'rgba(53, 162, 235, 0.7)',
                hoverBackgroundColor: 'rgba(53, 162, 235, 0.9)',
                hoverBorderColor: 'rgba(53, 162, 235, 1)',
                data: Object.values(dadosSolicitacoesLocalEstoque),
            }
        ],
    };

    const optionsLocalEstoque = {
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            },
        },
        // maintainAspectRatio: false,
        indexAxis: 'y', // Define a orientação para horizontal
        barPercentage: 0.3,
        responsive: true,
        barThickness: 20,
        maintainAspectRatio: false,
    };

    //Gráfico da média de abertura e fechamento das solicitações por mês
    const optionsAberturaEFechamento = {
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
        },
    };

    const dataAberturaEFechamento = {
        labels: Object.keys(dadosAberturasEFechamentos),
        datasets: [
            {
                label: 'Solicitações',
                data: Object.values(dadosAberturasEFechamentos),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ],
    };

    const obterEtapasDeAprovacao = () => {
        obterProcessosPorEtapaDeAprovacao(filtrosEtapaAprovacao, setDadosEtapaAprovacao);
    }

    const obterEntregasEmDiaEAtrasado = () => {
        obterEntregasEmDiaEAtrasados(filtrosEntregaEmDiaEAtrasado, setDadosEntregasEmDiaEAtrasado);
    }

    const obterSolicitacoesLocalEstoque = () => {
        console.log(filtrosSolicitacoesLocalEstoque.localEstoque);
        obterSolicitacoesLocaisEstoque(filtrosSolicitacoesLocalEstoque, setDadosSolicitacoesLocalEstoque);
    }

    const obterMediaAberturaFechamentos = () => {
        obterMediasAberturasEFechamentos(filtrosMediaAberturaFechamento, setDadosAberturasEFechamentos);
    }

    return (
        <Stack>
            {
                mobile ?
                    <Stack height="95vh" p="30px" justifyContent={"center"} alignItems="center">
                        <IoAlertCircleSharp color="#ffb300" size={30} />
                        <Typography textAlign={"center"}>O diligenciamento não é suportado por smartphones e tablets</Typography>
                    </Stack>
                    :
                    <Stack>
                        {/* Header */}
                        <Stack flexDirection={"row"} justifyContent="space-between" alignItems="center" backgroundColor="#222" padding="10px 20px">
                            <Stack flexDirection={"row"} alignItems="center">
                                {/* <img src={logoDiligenciamento} style={{ width: "30px", height: "30px", borderRadius: "15px" }} /> */}
                                <Typography sx={{ color: "#eee", fontSize: 20, marginLeft: "15px" }}>DASHBOARD</Typography>
                            </Stack>
                            <Stack flexDirection={"row"}>
                                <Stack>
                                    <Link onClick={() => navigate("/diligenciamento")} sx={{ marginRight: "30px" }}>Painel</Link>
                                </Stack>
                                <Stack>
                                    <Link onClick={() => navigate("/modulos")} sx={{ marginRight: "30px" }}>Ir para módulos</Link>
                                </Stack>
                                <Stack flexDirection={"row"}>
                                    <Typography sx={{ color: "#eee" }}>{usuarioLogado}</Typography>
                                    <Link onClick={() => [navigate("/"),]} sx={{ color: "#888", marginLeft: "10px" }}>sair</Link>
                                </Stack>
                            </Stack>
                        </Stack>
                        {/* Corpo da aplicação */}
                        <Stack margin="20px">
                            <Tabs aria-label="Basic tabs" defaultValue={0}>
                                <TabList>
                                    <Tab>Processos por Etapas de Aprovação</Tab>
                                    <Tab>Entregas (Em dia x Atrasados)</Tab>
                                    <Tab>Solicitações por Local de Estoque</Tab>
                                    <Tab>Média de Abertura e Fechamento de Solicitações Mensais</Tab>
                                </TabList>
                                <TabPanel value={0}>
                                    <Stack width="100%" height={"60vh"} mr="50px" mb="200px" mt="20px">
                                        <Typography level="title-lg">Processos por etapa de aprovação</Typography>
                                        <Stack flexDirection="row" alignItems="flex-end" mt="10px" mb="20px">
                                            <Stack>
                                                <Typography>Data Inicial</Typography>
                                                <Input value={filtrosEtapaAprovacao.dataInicial} onChange={(e) => setFiltrosEtapaAprovacao(prevState => ({ ...prevState, dataInicial: e.target.value }))} type="date" />
                                            </Stack>
                                            <Stack ml="20px" mr="20px">
                                                <Typography>Data Final</Typography>
                                                <Input value={filtrosEtapaAprovacao.dataFinal} onChange={(e) => setFiltrosEtapaAprovacao(prevState => ({ ...prevState, dataFinal: e.target.value }))} type="date" />
                                            </Stack>
                                            <Stack ml="20px" mr="20px">
                                                <Typography>Local de Estoque</Typography>
                                                <Select value={filtrosEtapaAprovacao.localEstoque} onChange={(e, newValue) => setFiltrosEtapaAprovacao(prevState => ({ ...prevState, localEstoque: newValue }))} sx={{ width: "385px" }}>
                                                    {locaisEstoque.map((localEstoque, indice) => {
                                                        return (
                                                            <Option key={indice} value={localEstoque.split(" - ")[0]}>{localEstoque}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Stack>
                                            <Button onClick={() => [obterEtapasDeAprovacao(), obterEntregasEmDiaEAtrasado(), obterSolicitacoesLocalEstoque()]}>Buscar</Button>
                                        </Stack>
                                        <Bar data={dataAtendimentoSemanais} options={options} />
                                    </Stack>
                                </TabPanel>
                                <TabPanel value={1}>
                                    <Stack width="100%" maxWidth="20vw" mb="50px" mr="200px" mt="20px">
                                        <Typography level="title-lg">Entregas Em dia / Atrasados</Typography>
                                        <Stack flexDirection="row" alignItems="flex-end" mt="10px" mb="20px">
                                            <Stack>
                                                <Typography>Data Inicial</Typography>
                                                <Input value={filtrosEntregaEmDiaEAtrasado.dataInicial} onChange={(e) => setFiltrosEntregaEmDiaEAtrasado(prevState => ({ ...prevState, dataInicial: e.target.value }))} type="date" sx={{ width: "160px" }} />
                                            </Stack>
                                            <Stack ml="20px" mr="20px">
                                                <Typography>Data Final</Typography>
                                                <Input value={filtrosEntregaEmDiaEAtrasado.dataFinal} onChange={(e) => setFiltrosEntregaEmDiaEAtrasado(prevState => ({ ...prevState, dataFinal: e.target.value }))} type="date" sx={{ width: "160px" }} />
                                            </Stack>
                                            <Button onClick={obterEntregasEmDiaEAtrasado}>Buscar</Button>
                                        </Stack>
                                        <Doughnut data={data} />
                                    </Stack>
                                </TabPanel>
                                <TabPanel value={2}>
                                    <Stack flexDirection={"row"} flex={1} mt="20px">
                                        <Stack flex={1} mb="50px" height={((labelsLocalEstoque.length * 100) / 2.2) + 'px'}>
                                            <Typography level="title-lg">Solicitações por local de estoque</Typography>
                                            <Stack flexDirection="row" alignItems="flex-end" mt="10px" mb="20px">
                                                <Stack>
                                                    <Typography>Data Inicial</Typography>
                                                    <Input value={filtrosSolicitacoesLocalEstoque.dataInicial} onChange={(e) => setFiltrosSolicitacoesLocalEstoque(prevState => ({ ...prevState, dataInicial: e.target.value }))} type="date" />
                                                </Stack>
                                                <Stack ml="20px" mr="20px">
                                                    <Typography>Data Final</Typography>
                                                    <Input value={filtrosSolicitacoesLocalEstoque.dataFinal} onChange={(e) => setFiltrosSolicitacoesLocalEstoque(prevState => ({ ...prevState, dataFinal: e.target.value }))} type="date" />
                                                </Stack>
                                                <Button onClick={() => [obterEtapasDeAprovacao(), obterEntregasEmDiaEAtrasado(), obterSolicitacoesLocalEstoque()]}>Buscar</Button>
                                            </Stack>
                                            <Bar data={dataLocalEstoque} options={optionsLocalEstoque} />
                                        </Stack>
                                    </Stack>
                                </TabPanel>
                                <TabPanel value={3}>
                                    <Stack width="100%" height={"60vh"} mt="20px">
                                        <Typography level="title-lg">Médias de aberturas e fechamentos de solicitações mensais</Typography>
                                        <Stack flexDirection="row" alignItems="flex-end" mt="10px" mb="20px">
                                            <Stack mr="20px">
                                                <Typography>Escolha o ano</Typography>
                                                <Select value={filtrosMediaAberturaFechamento.ano} onChange={(e, newValue) => setFiltrosMediaAberturasFechamentos(prevState => ({ ...prevState, ano: newValue }))}>
                                                    {anosDeFiltragem.map((ano, indice) => {
                                                        return (
                                                            <Option key={indice} value={ano}>{ano}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Stack>
                                            <Button onClick={obterMediaAberturaFechamentos}>Buscar</Button>
                                        </Stack>
                                        <Line options={optionsAberturaEFechamento} data={dataAberturaEFechamento} />
                                    </Stack>
                                </TabPanel>
                            </Tabs>
                        </Stack>
                    </Stack>
            }
        </Stack >
    )
}